import { Fragment, useState } from "react";
import type { ReactNode } from "react";
import { EmplacementCompteur } from "models";
import type { Compteur } from "models";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import type { ControlledInput } from "utils/rules";
import {
  Button,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { NumberField } from "components/Inputs";
import { getEmplacementCompteurLabel } from "utils/labelUtil";

interface CompteursProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {}

export default function Compteurs<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  control,
  defaultValue,
  rules,
}: Readonly<CompteursProps<T, Name>>): ReactNode {
  const [quantite, setQuantite] = useState<number>();
  const [calibre, setCalibre] = useState<number>();
  const [emplacement, setEmplacement] = useState<EmplacementCompteur>();

  const {
    field: { value, onChange },
  } = useController({ name, control, rules, defaultValue });

  function addCompteur(): void {
    if (quantite != null && calibre != null && emplacement != null) {
      onChange([
        ...value,
        {
          nombre: quantite,
          calibre,
          emplacement,
        },
      ]);
      setQuantite(0);
      setCalibre(0);
      setEmplacement(undefined);
    }
  }

  function deleteCompteurByIndex(index: number): void {
    const compteurs = [...value];
    compteurs.splice(index, 1);
    onChange(compteurs);
  }

  return (
    <Grid container spacing={1} size={12}>
      <Grid container size={12}>
        <Grid size={3} sx={{ paddingX: 2 }}>
          <Typography variant="subtitle2">Quantité</Typography>
        </Grid>
        <Grid size={3} sx={{ paddingX: 2 }}>
          <Typography variant="subtitle2">Calibre</Typography>
        </Grid>
        <Grid size={3} sx={{ paddingX: 2 }}>
          <Typography variant="subtitle2">Emplacement</Typography>
        </Grid>
      </Grid>
      {value?.map(({ nombre, calibre, emplacement }: Compteur, i: number) => (
        <Fragment key={`${nombre}${calibre}${emplacement}${i}`}>
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid container alignItems="center" size={12}>
            <Grid size={3} sx={{ paddingX: 2 }}>
              <Typography>{nombre}</Typography>
            </Grid>
            <Grid size={3} sx={{ paddingX: 2 }}>
              <Typography>{calibre}</Typography>
            </Grid>
            <Grid size="grow" sx={{ paddingX: 2 }}>
              <Typography>{getEmplacementCompteurLabel(emplacement)}</Typography>
            </Grid>
            <Grid size="auto">
              <IconButton
                onClick={() => {
                  deleteCompteurByIndex(i);
                }}
                size="small">
                <Tooltip title="Supprimer ce compteur" placement="bottom" disableInteractive>
                  <DeleteIcon color="error" />
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
        </Fragment>
      ))}
      <Grid container spacing={1} size={12}>
        <Grid container size={3}>
          <NumberField
            value={quantite ?? ""}
            onChange={setQuantite}
            type="entier"
            placeholder="Quantité"
            fullWidth
          />
        </Grid>
        <Grid container size={3}>
          <NumberField
            value={calibre ?? ""}
            onChange={setCalibre}
            type="entier"
            placeholder="Calibre"
            fullWidth
          />
        </Grid>
        <Grid container size="grow">
          <TextField
            select
            fullWidth
            value={emplacement ?? ""}
            onChange={({ target: { value } }) => {
              setEmplacement(value as EmplacementCompteur);
            }}>
            {Object.values(EmplacementCompteur).map((emplacementCompteur) => (
              <MenuItem key={emplacementCompteur} value={emplacementCompteur}>
                {getEmplacementCompteurLabel(emplacementCompteur)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid container size="auto">
          <Button startIcon={<AddIcon />} variant="contained" onClick={addCompteur}>
            Ajouter
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
