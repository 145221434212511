import type { ReactNode } from "react";
import { useMemo } from "react";
import { Chip, Grid2 as Grid, Typography } from "@mui/material";
import type { Grid2Props } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import type { DocumentDataConditionsDto } from "models";
import { grey } from "@mui/material/colors";

interface DocumentConditionChipProps {
  /** Libellé de la condition, il sera toujours affiché. */
  conditionLabel?: string;
  /**
   * Indique si la condition est vérifiée.
   *
   * Cette valeur est utilisée pour conditionnée par exemple :
   * - la couleur
   * - l'icone
   * - l'affichage du complément
   */
  conditionVerified?: boolean;
  /** Information complémentaire à afficher lorsque la condition est vérifiée. */
  complement?: string;
}

function DocumentConditionChip({
  conditionLabel,
  conditionVerified = false,
  complement,
}: Readonly<DocumentConditionChipProps>): ReactNode {
  const label = useMemo(() => {
    if (complement == null || complement === "" || !conditionVerified) {
      return conditionLabel;
    }

    return conditionLabel == null ? complement : `${conditionLabel} : ${complement}`;
  }, [conditionLabel, conditionVerified, complement]);

  return (
    <Chip
      size="small"
      label={label}
      sx={{ color: conditionVerified ? "success" : grey[500] }}
      color={conditionVerified ? "success" : undefined}
      variant={conditionVerified ? "filled" : "outlined"}
      icon={conditionVerified ? <CheckIcon /> : <ClearIcon color="inherit" />}
    />
  );
}

interface DocumentCourriersConditionsProps extends Grid2Props {
  conditions?: DocumentDataConditionsDto;
}
export function DocumentCourriersConditions({
  conditions,
  ...gridProps
}: Readonly<DocumentCourriersConditionsProps>): ReactNode {
  if (conditions == null) {
    return undefined;
  }

  return (
    <Grid size={12} container spacing={1} {...gridProps}>
      <Grid size={12} container spacing={1} alignItems="center">
        <Typography fontWeight={500}>Dossiers : </Typography>

        <DocumentConditionChip conditionLabel="EAU" conditionVerified={conditions.dossierEau} />
        <DocumentConditionChip
          conditionLabel="EAU seul"
          conditionVerified={conditions.dossierEauSansAss}
        />
        <DocumentConditionChip conditionLabel="ASS" conditionVerified={conditions.dossierAss} />
        <DocumentConditionChip
          conditionLabel="ASS séparatif seul"
          conditionVerified={conditions.branchementAssSeparatifSansEau}
        />
      </Grid>

      <Grid size={12} container spacing={1}>
        <DocumentConditionChip
          conditionLabel="Route départementale"
          conditionVerified={conditions.routeDepartementale}
        />
        <DocumentConditionChip
          conditionLabel="PAC"
          conditionVerified={conditions.pac}
          complement={conditions.pacMontant + " €"}
        />
        <DocumentConditionChip
          conditionLabel="Frais d'accès"
          conditionVerified={conditions.fraisAccesEau}
          complement={conditions.fraisAccesEauMontant + " €"}
        />
      </Grid>

      <Grid size={12} container spacing={1} alignItems="center">
        <Typography fontWeight={500}>Type d'intervention ASS :</Typography>

        <DocumentConditionChip
          conditionVerified={true}
          complement={conditions.typeInterventionAss}
        />
      </Grid>

      <Grid size={12} container spacing={1} alignItems="center">
        <Typography fontWeight={500}>EMS :</Typography>

        <DocumentConditionChip conditionLabel="EAU" conditionVerified={conditions.emsEau} />
        <DocumentConditionChip conditionLabel="ASS" conditionVerified={conditions.emsAss} />
      </Grid>

      <Grid size={12} container spacing={1} alignItems="center">
        <Typography fontWeight={500}>Molsheim :</Typography>

        <DocumentConditionChip conditionLabel="EAU" conditionVerified={conditions.molsheimEau} />
        <DocumentConditionChip conditionLabel="ASS" conditionVerified={conditions.molsheimAss} />
      </Grid>
    </Grid>
  );
}
