import type { ReactNode } from "react";
import { Grid2 as Grid, Skeleton, Typography } from "@mui/material";
import type { Grid2Props } from "@mui/material";

function DocumentConditionChipSkeleton(): ReactNode {
  return (
    <Grid>
      <Skeleton width="100px" />
    </Grid>
  );
}

export function DocumentCourriersConditionsSkeleton({
  ...gridProps
}: Readonly<Grid2Props>): ReactNode {
  return (
    <Grid size={12} container spacing={1} {...gridProps} alignItems="center">
      <Grid size={12} container spacing={1}>
        <Grid>
          <Typography>Dossiers : </Typography>
        </Grid>
        <Grid>
          <DocumentConditionChipSkeleton />
        </Grid>
        <Grid>
          <DocumentConditionChipSkeleton />
        </Grid>
        <Grid>
          <DocumentConditionChipSkeleton />
        </Grid>
        <Grid>
          <DocumentConditionChipSkeleton />
        </Grid>
      </Grid>

      <Grid size={12} container spacing={1}>
        <DocumentConditionChipSkeleton />
        <DocumentConditionChipSkeleton />
        <DocumentConditionChipSkeleton />
      </Grid>

      <Grid size={12} container spacing={1} alignItems="center">
        <Grid>
          <Typography>EMS :</Typography>
        </Grid>
        <Grid>
          <DocumentConditionChipSkeleton />
        </Grid>
        <Grid>
          <DocumentConditionChipSkeleton />
        </Grid>
      </Grid>

      <Grid size={12} container spacing={1} alignItems="center">
        <Grid>
          <Typography>Molsheim :</Typography>
        </Grid>
        <Grid>
          <DocumentConditionChipSkeleton />
        </Grid>
        <Grid>
          <DocumentConditionChipSkeleton />
        </Grid>
      </Grid>

      <Grid size={12} container spacing={1} alignItems="center">
        <Grid>
          <Typography>Type d'intervention ASS :</Typography>
        </Grid>
        <DocumentConditionChipSkeleton />
      </Grid>
    </Grid>
  );
}
