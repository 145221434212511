import { Box, Button, Grid2 as Grid, Typography } from "@mui/material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { useRef } from "react";
import type { PropsWithChildren, ReactNode } from "react";
import { useReactToPrint } from "react-to-print";
import type { Chantier, Demandeur, DossierAss, DossierCipa, DossierEau } from "models";

interface InfoRowProps extends PropsWithChildren {
  label?: string;
  value?: string;
}
function InfoRow({ label, value, children }: Readonly<InfoRowProps>): ReactNode {
  return (
    <Grid size={12} container alignItems="baseline" columnSpacing={2}>
      <Grid size={value != null && value.length > 0 ? 4 : 12}>
        <Typography variant="h6">{label}</Typography>
      </Grid>
      {children ?? (
        <Grid size={label != null && label.length > 0 ? 8 : 12}>
          <Typography>{value}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

function InfoChildRow({ label, value }: Readonly<InfoRowProps>): ReactNode {
  return (
    <Grid size={12} container alignItems="baseline" columnSpacing={2}>
      <Grid size={4}>
        {label != null && label.length > 0 && <Typography textAlign="right">{label} :</Typography>}
      </Grid>
      <Grid size={8}>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  );
}

function demandeurFullname(demandeur: Demandeur): string {
  return demandeur != null ? `${demandeur.nom ?? ""} ${demandeur.prenom ?? ""}` : "";
}

interface DocumentCadreButtonProps {
  chantier: Chantier;
  dossier: DossierAss | DossierEau | DossierCipa;
  dossierReference?: string;
}
export function DocumentCadreButton({
  dossier,
  chantier,
}: Readonly<DocumentCadreButtonProps>): ReactNode {
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({
    documentTitle: `Cadre ${dossier.reference}`,
    contentRef,
  });

  return (
    <Box>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          reactToPrintFn();
        }}
        startIcon={<LocalPrintshopIcon color="primary" />}>
        Cadre
      </Button>
      <Box
        ref={contentRef}
        sx={{
          p: 6,
          display: "none",
          "@media print": {
            display: "block",
            pt: 10,
          },
        }}>
        <Grid container spacing={4}>
          <Grid size="auto" alignContent="center">
            <Box
              sx={{
                writingMode: "vertical-lr",
                transform: "rotate(180deg)",
                float: "left",
                mr: 3,
              }}>
              <Typography variant="h2">N° Dossier : {dossier.reference}</Typography>
            </Box>
          </Grid>
          <Grid container size="grow" rowSpacing={1}>
            <InfoRow label="Type de rejet" value="Type de rejet non implémenté" />
            <InfoRow label="Affaire suivie par" value={dossier.technicien.displayName} />
            <InfoRow label="Adresse chantier">
              <InfoChildRow label="Commune" value={chantier.adresse.libelleCommune} />
              <InfoChildRow label="Propriétaire" value={demandeurFullname(chantier.demandeur1)} />
              {chantier.demandeur2 != null && (
                <>
                  <InfoChildRow label="" value={demandeurFullname(chantier.demandeur2)} />
                  <InfoChildRow label="Adresse" value={chantier.adresse.adresseVoie} />
                </>
              )}
            </InfoRow>
            <InfoRow label="Adresse actuelle">
              <InfoChildRow label="Adresse" value={chantier.demandeur1.adresseRue} />
              <InfoChildRow label="Commune" value={chantier.demandeur1.adresseCommune} />
              <InfoChildRow label="Code postal" value={chantier.demandeur1.adresseCodePostal} />
            </InfoRow>
            <InfoRow label="Observations">
              <Typography>Observations non implémentées</Typography>
            </InfoRow>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
