import type { ReactNode } from "react";
import Grid from "@mui/material/Grid2";
import { TypeDevis } from "models";
import type { DossierAss } from "models";
import { withPageErrorBoundary } from "utils/errorHandling";
import { LoadingScreen } from "components/Loading";
import { useDossier } from "providers";
import DossierSections from "../DossierSections";
import { dossierAssSections } from "../../routes/dossiersSectionsDefinitions";
import { DevisProvider } from "pages/Chantier/providers/DevisProvider";
import { DevisFormSectionWithErrorBoundary } from "../Devis";

function DossierAssFormTechnique(): ReactNode {
  const { dossier, isLoading } = useDossier<DossierAss>();

  return (
    <Grid container>
      <DossierSections isLoading={false} sections={dossierAssSections} noSaveButton />

      {dossier == null || isLoading ? (
        <LoadingScreen />
      ) : (
        <DevisProvider idChantier={dossier.idChantier} idDossier={dossier.id}>
          <DevisFormSectionWithErrorBoundary type={TypeDevis.ASS} />
        </DevisProvider>
      )}
    </Grid>
  );
}

export const DossierAssFormTechniqueWithErrorBoundary =
  withPageErrorBoundary(DossierAssFormTechnique);
