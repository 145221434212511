import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import type { ReactNode } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import type { ControlledInput } from "utils/rules";

interface ControlledBooleanRadioProps<T extends FieldValues, Name extends FieldPath<T>>
  extends ControlledInput<T, Name> {
  label?: string;
}

export function ControlledBooleanRadio<T extends FieldValues, Name extends FieldPath<T>>({
  name,
  control,
  label,
  defaultValue,
  rules,
}: Readonly<ControlledBooleanRadioProps<T, Name>>): ReactNode {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  return (
    <FormControl sx={{ display: "inline-block" }}>
      {label != null && <FormLabel sx={{ display: "inline", pr: 2 }}>{label}</FormLabel>}
      <RadioGroup
        value={value ?? null}
        sx={{ display: "inline-block" }}
        onChange={(_, newValue) => {
          onChange(newValue === "true");
        }}
        defaultValue={defaultValue}>
        <FormControlLabel value={true} control={<Radio />} label="Oui" sx={{ pr: 2 }} />
        <FormControlLabel value={false} control={<Radio />} label="Non" />
      </RadioGroup>
      <FormHelperText error={error != null}>{error?.message}</FormHelperText>
    </FormControl>
  );
}
