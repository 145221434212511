import type { ReactNode } from "react";
import { MenuItem, Grid2 as Grid, FormControlLabel, Radio } from "@mui/material";
import { Acceptation, DiametreBranchement, NatureConduite } from "models";
import { FormSection } from "components/Layout";
import {
  ControlledBooleanRadio,
  ControlledTextField,
  ControlledNumberField,
  ControlledRadioGroup,
} from "components/Inputs";
import {
  getAcceptationLabel,
  getDiametreBranchementLabel,
  getNatureConduiteLabel,
} from "utils/labelUtil";
import AspectFinancierAssForm from "../DossierAss/AspectFinancierAssForm";

export function DevisAssForm(): ReactNode {
  return (
    <Grid container spacing={2} size={12}>
      <FormSection title="Devis accepté par le demandeur">
        <Grid size={{ xs: 12 }}>
          <ControlledRadioGroup name="acceptation">
            {Object.entries(Acceptation).map(([key, acceptation]) => {
              return (
                <FormControlLabel
                  key={key}
                  value={acceptation}
                  control={<Radio />}
                  label={getAcceptationLabel(acceptation)}
                  sx={{ pr: 2 }}
                />
              );
            })}
          </ControlledRadioGroup>
        </Grid>
      </FormSection>

      <FormSection title="Caractéristiques">
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <ControlledBooleanRadio name="servitudeDePose" label="Servitude de pose" />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 9 }}>
          <ControlledTextField
            name="descriptionTravaux"
            label="Description des travaux"
            multiline
          />
        </Grid>
      </FormSection>

      <FormSection title="Eaux usées / Unitaire">
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <ControlledTextField
            name="branchementParticulier.natureConduite"
            label="Nature de la conduite"
            select>
            {Object.entries(NatureConduite).map(([key, natureConduite]) => (
              <MenuItem key={key} value={natureConduite}>
                {getNatureConduiteLabel(natureConduite)}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <ControlledNumberField
            name="branchementParticulier.longueur"
            label="Longueur en mètres"
            type="entier"
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <ControlledTextField name="branchementParticulier.diametre" label="Diamètre en mm" select>
            {Object.entries(DiametreBranchement).map(([key, diametre]) => (
              <MenuItem key={key} value={diametre}>
                {getDiametreBranchementLabel(diametre)}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <ControlledNumberField
            name="branchementParticulier.nombreDeRegards"
            label="Nombre de regards"
            type="entier"
          />
        </Grid>
      </FormSection>

      <FormSection title="Eaux pluviales">
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <ControlledTextField
            name="eauxPluviales.natureConduite"
            label="Nature de la conduite"
            select>
            {Object.entries(NatureConduite).map(([key, natureConduite]) => (
              <MenuItem key={key} value={natureConduite}>
                {getNatureConduiteLabel(natureConduite)}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <ControlledNumberField
            name="eauxPluviales.longueur"
            label="Longueur en mètres"
            type="entier"
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <ControlledTextField name="eauxPluviales.diametre" label="Diamètre en mm" select>
            {Object.entries(DiametreBranchement).map(([key, diametre]) => (
              <MenuItem key={key} value={diametre}>
                {getDiametreBranchementLabel(diametre)}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <ControlledNumberField
            name="eauxPluviales.nombreDeRegards"
            label="Nombre de regards"
            type="entier"
          />
        </Grid>
      </FormSection>
      <FormSection title="Aspect Financier" size={12}>
        <AspectFinancierAssForm />
      </FormSection>
    </Grid>
  );
}
