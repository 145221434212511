import type { DiametreBranchement, DossierBase, NatureConduite, TypeDossier } from "models";

export enum StatutDossierEau {
  EN_COURS_TRAITEMENT = "En cours de traitement",
  ATTENTE_REALISATION_TRAVAUX = "En attente de réalisation des travaux",
  ATTENTE_DOCUMENTS = "En attente de documents",
  TRAVAUX_REALISES_ATTENTE_FACTURE_FOURNISSEUR = "Travaux réalisés attente facture fournisseur",
  CLOS = "Clos (travaux exécutés)",
  ANNULE = "Annulé",
  ERREUR_DEMANDE = "Erreur demande",
  AUTRE = "Autres (voir observations)",
}

export enum TypeInterventionEau {
  COMPTEUR_EAU_POSE = "Compteur d'eau : pose",
  COMPTEUR_EAU_REMPLACEMENT = "Compteur d'eau : remplacement suite gel",
  PARTICULIER_REALISATION_COMPLETE = "Branchement particulier : réalisation complète",
  PARTICULIER_AMORCE = "Branchement particulier : amorce",
  PARTICULIER_ACHEVEMENT = "Branchement particulier : achèvement",
  PARTICULIER_FERMETURE_TEMPORAIRE = "Branchement particulier : fermeture temporaire",
  PARTICULIER_FERMETURE_RESILIATION = "Branchement particulier : fermeture suite résiliation d'abonnement",
  PARTICULIER_REMPLACEMENT = "Branchement particulier : remplacement",
  PARTICULIER_MODIFICATION = "Branchement particulier : modification",
  PARTICULIER_REOUVERTURE = "Branchement particulier : réouverture suite fermeture temporaire",
  AUTRE = "Autre (voir observations)",
}

export interface BranchementParticulierEau {
  travauxDeTerrassement?: boolean | null;
  percementDuMurCave?: boolean | null;
  natureConduite?: NatureConduite;
  diametre?: DiametreBranchement;
  longueur?: number;
  nombreDeRegards?: number;
  remarque?: string;
}

export enum EmplacementCompteur {
  REGARD_LEGER = "UnRegardLeger",
  REGARD_BETON = "UnRegardBeton",
  CAVE = "Cave",
  AUTRE = "Autre",
}

export interface Compteur {
  id: string;
  nombre: number;
  calibre: number;
  emplacement: EmplacementCompteur;
}

export interface CompteurModifyDTO {
  nombre: number;
  calibre: number;
  emplacement: EmplacementCompteur;
}

export interface DossierEau extends DossierBase {
  statutDemande: StatutDossierEau;
  type: TypeDossier.EAU;
  dateDemande: string;

  // #region Données administratives
  dateDossierComplet?: string;
  dateEnvoiDevisClient?: string;
  dateAcceptationTravaux?: string;
  dateDemandePiecesDossier?: string;
  dateReceptionAcompte?: string;
  dateDebutTravaux?: string;
  dateRealisationTravaux?: string;
  dateSouscription?: string;

  typeIntervention?: TypeInterventionEau;
  observations?: string;

  permissionVoirie?: boolean;
  dateDemandeVoirie?: string;
  dateAutorisationVoirie?: string;

  travauxRegie?: boolean;
  idFournisseur?: string;
  libelleFournisseur?: string;
  dateDemandeDevis?: string;
  dateRetourDevis?: string;
  dateBonCommande?: string;
  // #endregion

  destinataireCopie?: string;
}

export interface DossierEauAdministratifModifyDTO {
  idTechnicien?: string;
  nameTechnicien?: string;
  mailTechnicien?: string;
  centreTechnicien?: string;
  telephoneTechnicien?: string;

  statutDemande: StatutDossierEau;
  dateDemande: Date;

  dateDossierComplet?: Date;
  dateEnvoiDevisClient?: Date;
  dateAcceptationTravaux?: Date;
  dateDemandePiecesDossier?: Date;
  dateReceptionAcompte?: Date;
  dateDebutTravaux?: Date;
  dateRealisationTravaux?: Date;
  dateSouscription?: Date;

  typeIntervention?: TypeInterventionEau;
  observations?: string;

  permissionVoirie?: boolean | null;
  dateDemandeVoirie?: Date;
  dateAutorisationVoirie?: Date;

  travauxRegie?: boolean | null;
  idFournisseur?: string;
  libelleFournisseur?: string;
  dateDemandeDevis?: Date;
  dateRetourDevis?: Date;
  dateBonCommande?: Date;
}
