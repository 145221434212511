import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  List,
  ListItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import LoadingButton from "components/LoadingButton";
import { useFileSelect } from "./providers/FileSelectProvider";
import SelectableFileItem from "./SelectableFileItem";
import { useSnackbarErrorHandler } from "utils/errorHandling";
import { emailService } from "services";
import { useChantier } from "providers";
import type { File } from "models";
import FileItem from "./FileItem";
import { fileUtil } from "utils/fileUtil";
import TurnLeftIcon from "@mui/icons-material/TurnLeft";
import { TransitionGroup } from "react-transition-group";
import SendIcon from "@mui/icons-material/Send";

export function SelectedFilesList(): ReactNode {
  const [convertToPdf, setConvertToPdf] = useState(true);
  const { selectedFiles } = useFileSelect();
  const [fileItemIdsAsOriginal, setFileItemIdsAsOriginal] = useState<Array<string>>([]);
  const [fileItemIdsAsPdf, setFileItemIdsAsPdf] = useState<Array<string>>([]);

  useEffect(() => {
    if (convertToPdf) {
      setFileItemIdsAsOriginal(
        selectedFiles.filter((f) => !fileUtil.isWordFileFilter(f)).map((f) => f.id)
      );
      setFileItemIdsAsPdf(selectedFiles.filter(fileUtil.isWordFileFilter).map((f) => f.id));
    } else {
      setFileItemIdsAsOriginal(selectedFiles.map((f) => f.id));
      setFileItemIdsAsPdf([]);
    }
  }, [selectedFiles, convertToPdf]);

  const { chantier } = useChantier();
  const { catchErrors, isLoading } = useSnackbarErrorHandler();

  function toggleConvertToPdf(): void {
    setConvertToPdf(!convertToPdf);
  }

  function generateAndDownloadEmail(): void {
    if (chantier != null) {
      const payload = {
        attachmentsItemIds: {
          asPdf: fileItemIdsAsPdf,
          asOriginal: fileItemIdsAsOriginal,
        },
      };

      void catchErrors(async () => {
        const emailString = await emailService.generateEmail(chantier.id, payload);
        fileUtil.downloadStreamAsFile(emailString, "email.eml", "message/rfc822");
      });
    }
  }

  return (
    <Grid
      container
      padding={2}
      size={12}
      spacing={2}
      direction="column"
      sx={{ height: "100%" }}
      alignItems="flex-start"
      justifyContent="flex-start">
      <Grid container spacing={1}>
        <Grid container size={12}>
          <Typography variant="h5" sx={{ ml: 1 }}>
            Fichiers sélectionnés
          </Typography>
        </Grid>
        <Grid size={12}>
          <FormControlLabel
            control={<Checkbox checked={convertToPdf} onClick={toggleConvertToPdf} />}
            label="Convertir les words en pdfs"
          />
        </Grid>
        <Grid size={12}>
          <LoadingButton
            sx={{ width: "100%" }}
            loading={isLoading}
            onClick={generateAndDownloadEmail}
            startIcon={<SendIcon />}>
            Envoyer ces fichiers par mail
          </LoadingButton>
        </Grid>
      </Grid>

      <Grid
        size="grow"
        sx={{
          overflow: "auto",
          width: "100%",
          pr: 1,
          pl: 1,
        }}
        component={Paper}
        variant="outlined">
        <Collapse in={selectedFiles.length === 0} sx={{ width: "100%" }}>
          <Typography
            variant="caption"
            sx={{ width: "100%", display: "block", pt: 2 }}
            textAlign="center">
            Aucun fichier n'est actuellement sélectionné
          </Typography>
        </Collapse>
        <List dense>
          <TransitionGroup component={null}>
            {selectedFiles.map((file: File) => (
              <Collapse key={file.id}>
                <ListItem key={file.id} sx={{ paddingX: 0 }}>
                  <Grid container key={file.id} sx={{ width: "100%" }} spacing={1}>
                    <Grid size={12}>
                      <SelectableFileItem key={file.id} file={file} />
                    </Grid>
                    {fileItemIdsAsPdf.includes(file.id) && (
                      <Grid size={12}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ opacity: 0.6, pl: 2, width: "100%" }}>
                          <TurnLeftIcon fontSize="small" sx={{ transform: "rotate(180deg)" }} />
                          <FileItem
                            file={{
                              ...file,
                              name: `${fileUtil.getFileNameWithoutExtension(file)}.pdf`,
                            }}
                          />
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              </Collapse>
            ))}
          </TransitionGroup>
        </List>
      </Grid>
    </Grid>
  );
}
