import type { PropsWithChildren, ReactNode } from "react";
import type { Adresse } from "models";
import { Button, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { Grid2Props } from "@mui/material/Grid2";
import { grey } from "@mui/material/colors";
import type { SvgIconComponent } from "@mui/icons-material";
import { AdressePrincipale, ComplementsDAdresse } from "components/Adresse";
import { routesConfig } from "config/app-config";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import NotesIcon from "@mui/icons-material/Notes";

interface DemandeurCardSectionProps extends PropsWithChildren {
  Icon?: SvgIconComponent;
  title: string;
  bonus?: ReactNode;
}

function DemandeurCardSection({
  Icon,
  title,
  bonus,
  children,
}: Readonly<DemandeurCardSectionProps>): ReactNode {
  return (
    <Grid container size={{ xs: 12, md: "grow" }} spacing={1}>
      <Grid container alignItems="center" spacing={1} size={12}>
        {Icon != null && <Icon sx={{ color: grey[500] }} />}
        <Typography variant="subtitle2">{title}</Typography>
        {bonus}
      </Grid>
      <Grid size={12}>{children}</Grid>
    </Grid>
  );
}

interface ChantierDemandeurCardProps extends Grid2Props {
  adresse: Adresse;
}

export function ChantierAdresseCard({
  adresse,
  ...gridProps
}: Readonly<ChantierDemandeurCardProps>): ReactNode {
  return (
    <Grid component={Paper} variant="outlined" container size={12} sx={{ p: 2 }} {...gridProps}>
      <Grid container size={12} alignItems="flex-start" sx={{ mt: 1 }}>
        <DemandeurCardSection
          Icon={HomeIcon}
          title="Adresse"
          bonus={
            <Grid sx={{ marginY: -3, ml: 1 }}>
              <Button
                href={routesConfig.modifierAdresse.getParameterPath(adresse.id)}
                size="small"
                variant="outlined"
                startIcon={<EditIcon />}>
                Modifier cette adresse
              </Button>
            </Grid>
          }>
          <AdressePrincipale adresse={adresse} displayCommuneAssocieeDeleguee />
        </DemandeurCardSection>

        <DemandeurCardSection Icon={NotesIcon} title="Complément d'adresse">
          <ComplementsDAdresse adresse={adresse} />
        </DemandeurCardSection>
      </Grid>
    </Grid>
  );
}
