/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import type { Devis, DevisAssCreateDto, DevisEauCreateDto } from "models";
import type { PropsWithChildren, ReactNode } from "react";
import { devisService } from "services";
import { useErrorHandler } from "utils/errorHandling";
import { dateUtil } from "@sdeapps/react-core";
import type { Operation } from "fast-json-patch";

export interface DevisContextData {
  idChantier: string;
  idDossier: string;
  /**
   * Tableau de tous les Devis d'un Dossier
   */
  dossierDevis: Array<Devis>;
  addDevis: (data: DevisEauCreateDto | DevisAssCreateDto) => Promise<void>;
  deleteDevis: (idDevis: string) => Promise<void>;
  getDevis: (idDevis: string) => Promise<void>;
  getAllDevis: () => Promise<void>;
  patchDevis: (idDevis: string, patchData: Array<Operation>) => Promise<void>;
  isLoading: boolean;
  isAdding: boolean;
  isDeleting: boolean;
  isGetting: boolean;
  isGettingAll: boolean;
  lastNewDevisId?: string;
}

const devisContextDefaultData: DevisContextData = {
  idChantier: "",
  idDossier: "",
  dossierDevis: [],
  isLoading: false,
  addDevis: async (): Promise<void> => {},
  deleteDevis: async (): Promise<void> => {},
  getDevis: async (): Promise<void> => {},
  getAllDevis: async (): Promise<void> => {},
  patchDevis: async (): Promise<void> => {},
  isAdding: false,
  isDeleting: false,
  isGetting: false,
  isGettingAll: false,
  lastNewDevisId: undefined,
};

const DevisContext = createContext(devisContextDefaultData);

interface DevisProviderProps extends PropsWithChildren {
  idChantier: string;
  idDossier: string;
}

export function DevisProvider({
  idChantier,
  idDossier,
  children,
}: Readonly<DevisProviderProps>): ReactNode {
  const [dossierDevis, setDossierDevis] = useState<Array<Devis>>([]);
  const [lastNewDevisId, setLastNewDevisId] = useState<string>();

  const { catchErrors: catchAddErrors, isLoading: isAdding } = useErrorHandler({
    defaultIsLoading: false,
  });
  const { catchErrors: catchDeleteErrors, isLoading: isDeleting } = useErrorHandler({
    defaultIsLoading: false,
  });
  const { catchErrors: catchGetErrors, isLoading: isGetting } = useErrorHandler({
    defaultIsLoading: false,
  });
  const { catchErrors: catchGetAllErrors, isLoading: isGettingAll } = useErrorHandler();

  const isLoading = useMemo(
    () => isAdding || isDeleting || isGetting || isGettingAll,
    [isAdding, isDeleting, isGetting, isGettingAll]
  );

  const getAllDevis = useCallback(async (): Promise<void> => {
    await catchGetAllErrors(async () => {
      const _dossierDevis: Array<Devis> = await devisService.getDevisByDossier(
        idChantier,
        idDossier
      );
      _dossierDevis.sort((a, b) => dateUtil.compareDesc(a.dateCreation, b.dateCreation));
      setDossierDevis(_dossierDevis);
    });
  }, [catchGetAllErrors, idChantier, idDossier]);

  useEffect(() => {
    void getAllDevis();
  }, [getAllDevis]);

  const addDevis = useCallback(
    async (data: DevisEauCreateDto | DevisAssCreateDto): Promise<void> => {
      await catchAddErrors(async () => {
        const _newDevisId = await devisService.createDevis(idChantier, idDossier, data);
        setLastNewDevisId(_newDevisId);
        const _newDevis = await devisService.getDevisById(idChantier, idDossier, _newDevisId);
        const _dossierDevis = [_newDevis, ...dossierDevis];
        setDossierDevis(_dossierDevis);
      });
    },
    [catchAddErrors, dossierDevis, idChantier, idDossier]
  );

  const deleteDevis = useCallback(
    async (idDevis: string): Promise<void> => {
      await catchDeleteErrors(async () => {
        await devisService.deleteDevis(idChantier, idDossier, idDevis);
        const _dossierDevis = [...dossierDevis];
        const index = _dossierDevis.findIndex((d) => d.id === idDevis);
        if (index !== -1) {
          _dossierDevis.splice(index, 1);
        }
        setDossierDevis(_dossierDevis);
      });
    },
    [catchDeleteErrors, dossierDevis, idChantier, idDossier]
  );

  const getDevis = useCallback(
    async (idDevis: string): Promise<void> => {
      await catchGetErrors(async () => {
        const _updatedDevis = await devisService.getDevisById(idChantier, idDossier, idDevis);
        const _dossierDevis = dossierDevis.map((d) => {
          return d.id === idDevis ? _updatedDevis : d;
        });
        setDossierDevis(_dossierDevis);
      });
    },
    [catchGetErrors, dossierDevis, idChantier, idDossier]
  );

  const patchDevis = useCallback(
    async (idDevis: string, patchData: Array<Operation>): Promise<void> => {
      await devisService.patchDevis(idChantier, idDossier, idDevis, patchData);
      await getDevis(idDevis);
    },
    [getDevis, idChantier, idDossier]
  );

  const data: DevisContextData = useMemo(() => {
    return {
      idChantier,
      idDossier,
      dossierDevis,
      isLoading,
      addDevis,
      deleteDevis,
      getDevis,
      getAllDevis,
      patchDevis,
      isAdding,
      isDeleting,
      isGetting,
      isGettingAll,
      lastNewDevisId,
    };
  }, [
    idChantier,
    idDossier,
    dossierDevis,
    isLoading,
    addDevis,
    deleteDevis,
    getDevis,
    getAllDevis,
    patchDevis,
    isAdding,
    isDeleting,
    isGetting,
    isGettingAll,
    lastNewDevisId,
  ]);

  return <DevisContext.Provider value={data}>{children}</DevisContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useDevis(): DevisContextData {
  const context = useContext(DevisContext);

  if (context == null) {
    throw new Error(`${useDevis.name} must be used within a ${DevisProvider.name}`);
  }

  return context;
}
