import type { Devis, DevisAssCreateDto, DevisEauCreateDto, PatchData } from "models";
import { Endpoints } from "enums";
import { opaleApiClient } from "./apiClients";

/**
 * Crée un nouveau devis.
 * @returns L'id du devis créé.
 */
async function createDevis(
  idChantier: string,
  idDossier: string,
  data: DevisEauCreateDto | DevisAssCreateDto
): Promise<string> {
  const url = `/${Endpoints.CHANTIERS}/${idChantier}/${Endpoints.DOSSIERS}/${idDossier}/${Endpoints.DEVIS}`;
  return opaleApiClient.post<string, string>(url, data);
}

/**
 * Récupère les devis d'un Dossier.
 * @returns La liste des devis du Dossier.
 */
async function getDevisByDossier(idChantier: string, idDossier: string): Promise<Array<Devis>> {
  const url = `/${Endpoints.CHANTIERS}/${idChantier}/${Endpoints.DOSSIERS}/${idDossier}/${Endpoints.DEVIS}`;
  return opaleApiClient.get<Array<Devis>, Array<Devis>>(url);
}

/**
 * Récupère un devis d'un Dossier par son id.
 * @returns Un devis d'un Dossier.
 */
async function getDevisById(
  idChantier: string,
  idDossier: string,
  idDevis: string
): Promise<Devis> {
  const url = `/${Endpoints.CHANTIERS}/${idChantier}/${Endpoints.DOSSIERS}/${idDossier}/${Endpoints.DEVIS}/${idDevis}`;
  return opaleApiClient.get<Devis, Devis>(url);
}

/**
 * Met à jour les données d'un devis.
 */
async function patchDevis(
  idChantier: string,
  idDossier: string,
  idDevis: string,
  data: Array<PatchData>
): Promise<void> {
  const url = `/${Endpoints.CHANTIERS}/${idChantier}/${Endpoints.DOSSIERS}/${idDossier}/${Endpoints.DEVIS}/${idDevis}`;
  return opaleApiClient.patch(url, data);
}

/**
 * Supprime un devis.
 */
async function deleteDevis(idChantier: string, idDossier: string, idDevis: string): Promise<void> {
  const url = `/${Endpoints.CHANTIERS}/${idChantier}/${Endpoints.DOSSIERS}/${idDossier}/${Endpoints.DEVIS}/${idDevis}`;
  return opaleApiClient.delete(url);
}

export const devisService = {
  createDevis,
  getDevisById,
  getDevisByDossier,
  deleteDevis,
  patchDevis,
};
