import type { ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import { matchRoutes, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import { PageLayout } from "components/Layout";
import Grid from "@mui/material/Grid2";
import { chantierService } from "services";
import { enqueueSnackbar } from "notistack";
import { ToastMessages } from "enums";
import { routes, routesConfig } from "config/app-config";
import { LoadingScreen } from "components/Loading";
import { useRequiredParams } from "hooks";
import { ChantierTabs } from "./components/ChantierTabs";
import { grey } from "@mui/material/colors";
import { getDossiers } from "utils/dossierUtil";
import { TypeDossier } from "models";
import type { Chantier } from "models";
import { ChantierProvider } from "providers/ChantierProvider";
import { ChantierDemandeurs } from "./components/Demandeurs";
import { ChantierAdresse } from "./components/ChantierAdresse";

const pageTitle = "Chantier";

function ChantierDetails(): ReactNode {
  const { idChantier, idDossier, typeDossier } = useRequiredParams<{
    idChantier: string;
    idDossier: string;
    typeDossier: string | undefined;
  }>();
  const [selectedDossierType, setSelectedDossierType] = useState<TypeDossier>();
  const navigate = useNavigate();
  const [chantier, setChantier] = useState<Chantier>();
  const { catchErrors, isLoading } = useErrorHandler({
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR,
      });
    },
  });

  const updateChantier = useCallback(async (): Promise<void> => {
    async function loadChantier(): Promise<void> {
      const _chantier = await chantierService.getChantier(idChantier);

      setChantier(_chantier);
    }

    await catchErrors(loadChantier);
  }, [catchErrors, idChantier]);

  useEffect(() => {
    void updateChantier();
  }, [updateChantier]);

  const location = useLocation();

  // Failsafe : si on n'est pas sur une page de dossier, ou de création d'un nouveau dossier de type valide,
  // ou l'onglet des mails, on navigue vers le premier dossier de la liste.
  useEffect(() => {
    if (chantier != null) {
      const match = matchRoutes(routes, location);
      if (
        (idDossier == null &&
          typeDossier == null &&
          match?.[0]?.route?.path !== routesConfig.chantierMails.path) ||
        (typeDossier != null && !Object.values(TypeDossier).includes(typeDossier as TypeDossier))
      ) {
        navigate(
          routesConfig.chantierDossier.getParameterPath(
            idChantier,
            `${getDossiers(chantier)?.at(0)?.id}`
          ),
          { replace: true }
        );
      } else {
        setSelectedDossierType(
          getDossiers(chantier).find((dossier) => dossier.id === idDossier)?.type ??
            (typeDossier as TypeDossier)
        );
      }
    }
  }, [chantier, idDossier, idChantier, navigate, typeDossier, location]);

  if (chantier == null || isLoading) {
    return (
      <PageLayout pageTitle={pageTitle}>
        <LoadingScreen />
      </PageLayout>
    );
  }

  return (
    <PageLayout pageTitle={`${pageTitle} ${chantier.reference}`}>
      <Grid container>
        <Grid container sx={{ pb: 1 }} size={12}>
          <ChantierDemandeurs chantier={chantier} />
          <ChantierAdresse adresse={chantier.adresse} />
        </Grid>
        <ChantierProvider chantier={chantier} updateChantier={updateChantier} isLoading={isLoading}>
          <Grid size={12}>
            <ChantierTabs />
          </Grid>
          <Grid
            container
            alignContent="flex-start"
            sx={{
              border: "2px solid",
              borderColor:
                selectedDossierType != null
                  ? `${selectedDossierType?.toLowerCase()}.main`
                  : grey[500],
              minHeight: "25vh",
              background: grey[200],
            }}
            size={12}>
            <Outlet />
          </Grid>
        </ChantierProvider>
      </Grid>
    </PageLayout>
  );
}

export const ChantierDetailsWithErrorBoundary = withPageErrorBoundary(ChantierDetails);
