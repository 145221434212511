import { useCallback, useEffect, useState } from "react";
import type { ReactElement } from "react";
import {
  CircularProgress,
  Collapse,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { graphService } from "services";
import { useErrorHandler } from "utils/errorHandling";
import SyncIcon from "@mui/icons-material/Sync";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { yellow } from "@mui/material/colors";
import type { Folder, File } from "models";
import FolderList from "./FolderList";
import SelectableFileItem from "./SelectableFileItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { fileUtil } from "utils/fileUtil";
import { FolderIcon } from "icons/FolderIcon";
import { OpenedFolderIcon } from "icons/OpenedFolderIcon";

interface FileListPocProps {
  folderName: string;
}

function FileList({ folderName }: Readonly<FileListPocProps>): ReactElement {
  const [files, setFiles] = useState<Array<File>>([]);
  const [folders, setFolders] = useState<Array<Folder>>([]);
  const [folder, setFolder] = useState<Folder>();
  const [isOpen, setIsOpen] = useState(true);

  const { catchErrors, isLoading } = useErrorHandler();

  const getChildren = useCallback(async (): Promise<void> => {
    const filesAndFolders: Array<File | Folder> = await graphService.getFolderChildren(folderName);
    setFiles(filesAndFolders.filter(fileUtil.isFile));
    setFolders(filesAndFolders.filter(fileUtil.isFolder));
  }, [folderName]);

  const getFolder = useCallback(async (): Promise<void> => {
    setFolder(await graphService.getFolder(folderName));
  }, [folderName]);

  const getFolderAndChildren = useCallback((): void => {
    void catchErrors(getFolder);
    void catchErrors(getChildren);
  }, [catchErrors, getChildren, getFolder]);

  useEffect(() => {
    getFolderAndChildren();
  }, [getFolderAndChildren]);

  return (
    <Grid container padding={2} size={12}>
      <Grid container alignItems="center" spacing={2} size={12}>
        <Grid
          container
          size="auto"
          sx={{ ":hover": { cursor: "pointer" } }}
          onClick={() => {
            setIsOpen(!isOpen);
          }}>
          <Stack direction="row" alignItems="center">
            <IconButton size="small">
              <Tooltip
                title={isOpen ? "Fermer le répertoire" : "Ouvrir le répertoire"}
                placement="bottom"
                disableInteractive
                enterDelay={600}>
                {isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}
              </Tooltip>
            </IconButton>
            {isLoading && <CircularProgress size={27} />}
            {!isLoading &&
              (isOpen ? (
                <OpenedFolderIcon sx={{ color: yellow[700] }} />
              ) : (
                <FolderIcon sx={{ color: yellow[700] }} />
              ))}
            <Typography variant="h5" sx={{ ml: 1 }}>
              {folderName}
            </Typography>
          </Stack>
        </Grid>
        <Grid container>
          <IconButton
            loading={isLoading}
            onClick={getFolderAndChildren}
            color="primary"
            size="small">
            <Tooltip title="Rafraîchir" placement="bottom" disableInteractive enterDelay={600}>
              <SyncIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid size="grow">
          <Link href={folder?.webUrl} target="_blank" variant="body2" sx={{ lineHeight: "12px" }}>
            Ouvrir dans Sharepoint
            <OpenInNewIcon sx={{ fontSize: 10, ml: 0.5, verticalAlign: "super" }} />
          </Link>
        </Grid>
      </Grid>

      <Collapse in={isOpen} timeout={200} sx={{ width: "100%" }}>
        <Grid container size={12} sx={{ pl: 3 }} spacing={0.5}>
          {folders.map((folder) => (
            <Grid container size={12} key={folder.id}>
              <FolderList itemId={folder.id} />
            </Grid>
          ))}
          {files.map((file) => (
            <Grid container size={12} key={file.id}>
              <SelectableFileItem key={file.id} file={file} />
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Grid>
  );
}

export default FileList;
