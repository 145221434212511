import { useState } from "react";
import type { ReactNode } from "react";
import { Collapse, IconButton, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { Adresse } from "models";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ChantierAdresseCard } from "./ChantierAdresseCard";

interface ChantierDemandeurLineProps {
  adresse: Adresse;
}

function ChantierAdresseLine({ adresse }: Readonly<ChantierDemandeurLineProps>): ReactNode {
  return (
    <Grid container columnSpacing={2}>
      <Grid>
        <Typography>{adresse?.adresseVoie}</Typography>
      </Grid>
      <Grid>
        <Typography>
          {adresse?.codePostal} {adresse.libelleCommune}
        </Typography>
      </Grid>
      {adresse?.libelleCommuneAssocieeDeleguee != null &&
        adresse?.libelleCommuneAssocieeDeleguee !== "" && (
          <Grid>
            <Typography variant="caption">{adresse.libelleCommuneAssocieeDeleguee}</Typography>
          </Grid>
        )}
    </Grid>
  );
}

interface ChantierDemandeursProps {
  adresse: Adresse;
}

export function ChantierAdresse({ adresse }: Readonly<ChantierDemandeursProps>): ReactNode {
  const [isOpen, setIsOpen] = useState(false);

  function toggleIsOpen(): void {
    setIsOpen(!isOpen);
  }

  return (
    <Paper component={Grid} container size={12} variant="outlined" sx={{ border: 0 }}>
      <Grid
        container
        size={12}
        onClick={toggleIsOpen}
        sx={{ cursor: "pointer" }}
        alignItems="center"
        columnSpacing={2}>
        <Grid sx={{ marginRight: -2 }}>
          <IconButton size="small">{isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}</IconButton>
        </Grid>
        <Grid container alignItems="baseline">
          <Typography variant="subtitle2">
            Adresse du chantier
            {!isOpen && " : "}
          </Typography>
          {!isOpen && <ChantierAdresseLine adresse={adresse} />}
        </Grid>
      </Grid>

      <Collapse in={isOpen} sx={{ width: "100%" }}>
        <ChantierAdresseCard adresse={adresse} />
      </Collapse>
    </Paper>
  );
}
