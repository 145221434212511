/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useCallback, useContext, useMemo, useState } from "react";
import type { PropsWithChildren, ReactNode } from "react";
import type { File } from "models";

export type FileSelectData = {
  selectFile: (f: File) => void;
  unselectFile: (f: File) => void;
  selectedFiles: Array<File>;
};

const FileSelectContext = createContext<FileSelectData>({
  selectFile: () => {},
  unselectFile: () => {},
  selectedFiles: [],
});

export function FileSelectProvider({ children }: Readonly<PropsWithChildren>): ReactNode {
  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);

  const selectFile = useCallback(
    (fileToAdd: File): void => {
      setSelectedFiles([...selectedFiles, fileToAdd]);
    },
    [selectedFiles]
  );
  const unselectFile = useCallback(
    (fileToRemove: File): void => {
      setSelectedFiles(selectedFiles.filter((f) => f.id !== fileToRemove.id));
    },
    [selectedFiles]
  );

  const data: FileSelectData = useMemo(() => {
    return {
      selectFile,
      unselectFile,
      selectedFiles,
    };
  }, [selectFile, selectedFiles, unselectFile]);

  return <FileSelectContext.Provider value={data}>{children}</FileSelectContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useFileSelect(): FileSelectData {
  const context = useContext(FileSelectContext);

  if (context == null) {
    throw new Error(`${useFileSelect.name} must be used within a ${FileSelectProvider.name}`);
  }

  return context;
}
