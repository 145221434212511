import type { ReactNode } from "react";
import Grid from "@mui/material/Grid2";
import { Button, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import { FormSection } from "components/Layout";
import AddIcon from "@mui/icons-material/Add";
import { useDevis } from "../../providers/DevisProvider";
import SyncIcon from "@mui/icons-material/Sync";
import type { TypeDevis } from "models";
import { DevisAcccordionWithErrorBoundary } from ".";
import { withComponentErrorBoundary } from "utils/errorHandling";

function DevisSkeleton(): ReactNode {
  return <Skeleton height={100} sx={{ width: "100%", mt: -2, mb: -2 }} />;
}

interface DevisFormSectionProps {
  type: TypeDevis;
}

function DevisFormSection({ type }: Readonly<DevisFormSectionProps>): ReactNode {
  const { addDevis, dossierDevis, isGettingAll, getAllDevis, isAdding, lastNewDevisId, isLoading } =
    useDevis();

  return (
    <Grid container spacing={2} size={12}>
      <FormSection size={12} noBackground>
        <Grid container size={12}>
          <Button
            variant="contained"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => {
              await addDevis({ Type: type });
            }}
            startIcon={<AddIcon />}>
            Nouveau devis
          </Button>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <IconButton onClick={getAllDevis} loading={isGettingAll}>
            <Tooltip title="Rafraîchir" placement="bottom">
              <SyncIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        {isAdding && <DevisSkeleton />}
        {isGettingAll
          ? // On affiche un skeleton par devis existant, et au moins 1 skeleton
            (dossierDevis.length > 0 ? dossierDevis : [{ id: 1 }]).map((devis) => (
              <Grid container size={12} key={devis.id}>
                <DevisSkeleton />
              </Grid>
            ))
          : dossierDevis.map((devis) => (
              <DevisAcccordionWithErrorBoundary
                key={devis.id}
                devis={devis}
                // On ouvre le devis par défaut si c'est le seul ou qu'il vient d'être créé
                defaultExpanded={dossierDevis.length === 1 || devis.id === lastNewDevisId}
              />
            ))}
      </FormSection>
      {dossierDevis.length === 0 && !isLoading && (
        <FormSection size={12} sx={{ p: 2, pt: 0 }}>
          <Typography>Ce Dossier n'a pour l'instant aucun Devis.</Typography>
        </FormSection>
      )}
    </Grid>
  );
}

export const DevisFormSectionWithErrorBoundary = withComponentErrorBoundary(DevisFormSection);
