import type { ReactNode } from "react";
import Grid from "@mui/material/Grid2";
import { TypeDevis } from "models";
import type { DossierEau } from "models";
import { withPageErrorBoundary } from "utils/errorHandling";
import { LoadingScreen } from "components/Loading";
import { useDossier } from "providers";
import DossierSections from "../DossierSections";
import { dossierEauSections } from "../../routes/dossiersSectionsDefinitions";
import { DevisProvider } from "../../providers/DevisProvider";
import { DevisFormSectionWithErrorBoundary } from "../Devis/DevisFormSection";

function DossierEauFormTechnique(): ReactNode {
  const { dossier, isLoading } = useDossier<DossierEau>();

  return (
    <Grid container>
      <DossierSections isLoading={false} sections={dossierEauSections} noSaveButton />

      {dossier == null || isLoading ? (
        <LoadingScreen />
      ) : (
        <DevisProvider idChantier={dossier.idChantier} idDossier={dossier.id}>
          <DevisFormSectionWithErrorBoundary type={TypeDevis.EAU} />
        </DevisProvider>
      )}
    </Grid>
  );
}

export const DossierEauFormTechniqueWithErrorBoundary =
  withPageErrorBoundary(DossierEauFormTechnique);
