import type { CalculAss, CalculEau } from "models/Calcul";
import type {
  Compteur,
  BranchementParticulierEau,
  BranchementParticulierAss,
  CompteurModifyDTO,
} from "models/Dossier";

export enum Acceptation {
  OUI = "OUI",
  NON = "NON",
  EN_ATTENTE = "EN_ATTENTE",
}

export enum TypeDevis {
  EAU = "EAU",
  ASS = "ASS",
}

export interface BaseDevis {
  id: string;
  idDossier: string;
  type: TypeDevis;
  dateCreation?: string;
  acceptation: Acceptation;
}

export interface DevisEau extends BaseDevis {
  type: TypeDevis.EAU;
  servitudeDePose?: boolean;
  descriptionTravaux?: string;
  compteurs?: Array<Compteur>;
  ria?: boolean;
  calibreRia?: string;
  branchementParticulier?: BranchementParticulierEau;
  aspectFinancier?: CalculEau;
}

export interface DevisAss extends BaseDevis {
  type: TypeDevis.ASS;
  servitudeDePose?: boolean;
  descriptionTravaux?: string;
  branchementParticulier?: BranchementParticulierAss;
  eauxPluviales?: BranchementParticulierAss;
  aspectFinancier?: CalculAss;
}

export interface DevisEauCreateDto {
  /**
   * Il faut respecter la casse "Type" cf commentaires des tâches suivantes :
   * https://dev.azure.com/SDEA/SDEApps/_workitems/edit/14106
   * https://dev.azure.com/SDEA/SDEApps/_workitems/edit/14262
   * */
  Type: TypeDevis.EAU;
  acceptation?: Acceptation;
  servitudeDePose?: boolean;
  descriptionTravaux?: string;
  compteurs?: Array<CompteurModifyDTO>;
  ria?: boolean;
  calibreRia?: string;
  branchementParticulier?: BranchementParticulierEau;
  aspectFinancier?: CalculEau;
}

export interface DevisAssCreateDto {
  /**
   * Il faut respecter la casse "Type" cf commentaires des tâches suivantes :
   * https://dev.azure.com/SDEA/SDEApps/_workitems/edit/14106
   * https://dev.azure.com/SDEA/SDEApps/_workitems/edit/14262
   * */
  Type: TypeDevis.ASS;
  acceptation?: Acceptation;
  servitudeDePose?: boolean;
  descriptionTravaux?: string;
  branchementParticulier?: BranchementParticulierAss;
  eauxPluviales?: BranchementParticulierAss;
  aspectFinancier?: CalculAss;
}

export interface DevisEauPatchDto {
  acceptation?: Acceptation;
  servitudeDePose?: boolean;
  descriptionTravaux?: string;
  compteurs?: Array<CompteurModifyDTO>;
  ria?: boolean;
  calibreRia?: string;
  branchementParticulier?: BranchementParticulierEau;
  aspectFinancier?: CalculEau;
}

export interface DevisAssPatchDto {
  acceptation?: Acceptation;
  servitudeDePose?: boolean;
  descriptionTravaux?: string;
  branchementParticulier?: BranchementParticulierAss;
  eauxPluviales?: BranchementParticulierAss;
  aspectFinancier?: CalculAss;
}

export type Devis = DevisEau | DevisAss;
