import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import { numericFormatter } from "react-number-format";

function formatMontant(
  numberToFormat: string | number | undefined = 0,
  withEndEuro: boolean = false
): string {
  return `${numericFormatter(numberToFormat.toString(), {
    thousandSeparator: " ",
    decimalSeparator: ",",
    fixedDecimalScale: true,
    decimalScale: 2,
  })}${withEndEuro ? " €" : ""}`;
}

interface MontantTypographyProps {
  numberToFormat: string | number | undefined;
  emphasis?: boolean;
}

export function MontantTypography({
  numberToFormat,
  emphasis = false,
}: Readonly<MontantTypographyProps>): ReactNode {
  return (
    <Typography
      sx={{
        textAlign: "right",
        p: 2,
        paddingY: 1,
        backgroundColor: emphasis ? "info.light" : undefined,
        fontWeight: emphasis ? 500 : "normal",
      }}>
      {formatMontant(numberToFormat, true)}
    </Typography>
  );
}
