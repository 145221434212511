import type { CalculPac, DossierBase, TypeDossier } from "models";

export enum StatutDossierPac {
  EN_COURS_TRAITEMENT = "En cours de traitement",
  CLOS = "Clos",
}

export interface DossierPac extends DossierBase {
  statutDemande: StatutDossierPac;
  type: TypeDossier.PAC;
  observations?: string;

  isPacAppliquee?: boolean | null;

  aspectFinancier?: CalculPac;
}

export interface DossierPacModifyDTO {
  idTechnicien?: string;
  nameTechnicien?: string;
  mailTechnicien?: string;
  centreTechnicien?: string;
  telephoneTechnicien?: string;

  statutDemande: StatutDossierPac;
  observations?: string;

  isPacAppliquee?: boolean | null;

  aspectFinancier?: CalculPac;
}
