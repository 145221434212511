import { TypeDevis } from "models";
import type {
  DevisAss,
  Devis,
  DevisEau,
  DevisEauPatchDto,
  DevisAssPatchDto,
  CompteurModifyDTO,
} from "models";

function fromDevisEauToDevisEauPatchDto(devis: DevisEau): DevisEauPatchDto {
  const devisEau: DevisEauPatchDto = {
    ria: devis?.ria,
    acceptation: devis.acceptation,
    servitudeDePose: devis?.servitudeDePose,
    calibreRia: devis?.calibreRia,
    descriptionTravaux: devis?.descriptionTravaux,
    compteurs: devis?.compteurs?.map(({ id, ...compteur }) => {
      return { ...compteur } satisfies CompteurModifyDTO;
    }),
    branchementParticulier: devis?.branchementParticulier,
    aspectFinancier: devis?.aspectFinancier,
  };

  return devisEau;
}

function fromDevisAssToDevisAssPatchDto(devis: DevisAss): DevisAssPatchDto {
  const dossierAssTechnique: DevisAssPatchDto = {
    acceptation: devis.acceptation,
    servitudeDePose: devis?.servitudeDePose,
    descriptionTravaux: devis?.descriptionTravaux,
    branchementParticulier: devis?.branchementParticulier,
    eauxPluviales: devis?.eauxPluviales,
    aspectFinancier: devis?.aspectFinancier,
  };

  return dossierAssTechnique;
}

export function isDevisEau(devis: Devis): devis is DevisEau {
  return devis.type === TypeDevis.EAU;
}

export function isDevisAss(devis: Devis): devis is DevisAss {
  return devis.type === TypeDevis.ASS;
}

export const devisPatchUtils = {
  fromDevisEauToDevisEauPatchDto,
  fromDevisAssToDevisAssPatchDto,
};
