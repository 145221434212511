import { Endpoints } from "enums";
import type { CalculAss, CalculEau, CalculPac } from "models";
import { opaleApiClient } from "./apiClients";

async function postCalculEau(data: CalculEau): Promise<CalculEau> {
  const url = `/${Endpoints.CALCUL_ASPECT_FINANCIER_EAU_POTABLE}`;
  return opaleApiClient.post<CalculEau, CalculEau>(url, data);
}

async function postCalculAss(data: CalculAss): Promise<CalculAss> {
  const url = `/${Endpoints.CALCUL_ASPECT_FINANCIER_ASSAINISSEMENT}`;
  return opaleApiClient.post<CalculEau, CalculAss>(url, data);
}

async function postCalculAspectFinancierPac(data: CalculPac): Promise<CalculPac> {
  const url = `/${Endpoints.CALCUL_ASPECT_FINANCIER_PAC}`;
  return opaleApiClient.post<CalculEau, CalculPac>(url, data);
}

export const calculService = {
  postCalculAss,
  postCalculEau,
  postCalculAspectFinancierPac,
};
