import { useEffect, useState, type ReactNode } from "react";
import Grid from "@mui/material/Grid2";
import { ControlledObjectSelect } from "components/Inputs";
import { useFormContext, useWatch } from "react-hook-form";
import type { ChantierCreateData } from "models";
import { type PerimetresEauAss, useDossierPerimetre } from "hooks";
import { Typography } from "@mui/material";
import { SkeletonInput } from "components/Loading";
import { useErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";

interface PerimetresFormProps {
  gridSpacing?: number;
}

function PerimetresForm({ gridSpacing = 2 }: Readonly<PerimetresFormProps>): ReactNode {
  const { resetField, setValue, control } = useFormContext<ChantierCreateData>();
  const [perimetres, setPerimetres] = useState<PerimetresEauAss>();
  const codeInsee = useWatch({ control, name: "adresse.codeInsee" });
  const codeInseeCommuneAssocieeDeleguee = useWatch({
    control,
    name: "adresse.codeInseeCommuneAssocieeDeleguee",
  });
  const avisEau = useWatch({ control, name: "avis.avisEau" });
  const avisAss = useWatch({ control, name: "avis.avisAss" });
  const avisCipa = useWatch({ control, name: "avis.avisCipa" });
  const { getPerimetresByCommune } = useDossierPerimetre();
  const { catchErrors, isLoading } = useErrorHandler({ defaultIsLoading: false });

  useEffect(() => {
    async function getAllPerimetresForCommune(): Promise<void> {
      resetField("perimetres.perimetreEau");
      resetField("perimetres.perimetreAss");

      if (codeInsee != null) {
        const _perimetres = await getPerimetresByCommune(
          codeInsee,
          codeInseeCommuneAssocieeDeleguee
        );
        setPerimetres(_perimetres);

        if (avisEau != null && _perimetres?.perimetresEau?.length > 0) {
          setValue(
            "perimetres.perimetreEau",
            _perimetres?.perimetresEau?.length === 1 ? _perimetres?.perimetresEau[0] : undefined
          );
        }
        if (avisAss != null || avisCipa != null) {
          setValue(
            "perimetres.perimetreAss",
            _perimetres?.perimetresAss?.length === 1 ? _perimetres?.perimetresAss[0] : undefined
          );
        }
      }
    }

    void catchErrors(getAllPerimetresForCommune);
  }, [
    avisAss,
    avisCipa,
    avisEau,
    catchErrors,
    codeInsee,
    codeInseeCommuneAssocieeDeleguee,
    getPerimetresByCommune,
    resetField,
    setValue,
  ]);

  if (isLoading) {
    return (
      <Grid container alignItems="flex-start" spacing={gridSpacing} size={12}>
        {avisEau != null && (
          <Grid size={12}>
            <SkeletonInput />
          </Grid>
        )}
        {(avisAss != null || avisCipa != null) && (
          <Grid size={12}>
            <SkeletonInput />
          </Grid>
        )}
      </Grid>
    );
  }

  if (codeInsee == null || perimetres?.perimetresEau == null || perimetres?.perimetresAss == null) {
    return (
      <Grid container alignItems="flex-start" spacing={gridSpacing} size={12}>
        <Typography variant="caption">
          Sélectionnez une commune pour pouvoir sélectionner un périmètre.
        </Typography>
      </Grid>
    );
  }

  if (avisAss == null && avisEau == null && avisCipa == null) {
    return (
      <Typography variant="caption">
        Veuillez saisir un avis pour sélectionner un périmètre
      </Typography>
    );
  }

  return (
    <Grid container alignItems="flex-start" spacing={gridSpacing} size={12}>
      {avisEau != null && (
        <Grid size={12}>
          {perimetres?.perimetresEau?.length > 0 ? (
            <ControlledObjectSelect
              name="perimetres.perimetreEau"
              label="Périmètre EAU"
              menuItems={perimetres.perimetresEau}
              menuItemKeyName="id"
              menuItemLibelleName="libelle"
              rules={{
                validate: (_value) =>
                  avisEau == null ||
                  _value != null ||
                  "Veuillez choisir un périmètre pour l'avis EAU que vous avez sélectionné.",
              }}
              readOnly={perimetres.perimetresEau.length === 1}
              disabled={perimetres.perimetresEau.length === 1}
            />
          ) : (
            <Typography>Le SDEA n'est pas compétent en EAU pour la commune sélectionnée</Typography>
          )}
        </Grid>
      )}
      {(avisAss != null || avisCipa != null) && (
        <Grid size={12}>
          {perimetres?.perimetresAss?.length > 0 ? (
            <ControlledObjectSelect
              name="perimetres.perimetreAss"
              label="Perimètre ASS"
              menuItems={perimetres.perimetresAss}
              menuItemKeyName="id"
              menuItemLibelleName="libelle"
              rules={{
                validate: (_value) =>
                  (avisAss == null && avisCipa == null) ||
                  _value != null ||
                  "Veuillez choisir un périmètre pour l'avis ASS/CIPA que vous avez sélectionné.",
              }}
              readOnly={perimetres.perimetresAss.length === 1}
              disabled={perimetres.perimetresAss.length === 1}
            />
          ) : (
            <Typography>Le SDEA n'est pas compétent en ASS pour la commune sélectionnée</Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
}

const PerimetresFormWithErrorBoundary = withComponentErrorBoundary(PerimetresForm);
export default PerimetresFormWithErrorBoundary;
