import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { useCurrentPathRoute } from "hooks";
import { useDossier } from "providers";
import { useNavigate } from "react-router-dom";
import { withPageErrorBoundary } from "utils/errorHandling";
import type { DossierSectionDefinition } from "../routes/dossiersSectionsDefinitions";

interface DossierFomProps {
  sections: Array<DossierSectionDefinition>;
}

export function DossierForm({ sections }: Readonly<DossierFomProps>): ReactNode {
  const currentPath = useCurrentPathRoute();
  const navigate = useNavigate();

  const { dossier } = useDossier();
  const [currentRouteDefinition, setCurrentRouteDefinition] = useState<DossierSectionDefinition>();

  useEffect(() => {
    if (
      currentPath != null &&
      !sections.map((rd) => rd.path).includes(currentPath) &&
      dossier != null
    ) {
      navigate(sections[0].getParameterPath(dossier.idChantier, dossier.id), { replace: true });
    }

    setCurrentRouteDefinition(sections.find((rd) => rd.path === currentPath));
  }, [currentPath, dossier, sections, navigate]);

  if (currentRouteDefinition != null) {
    return <currentRouteDefinition.component />;
  }

  return null;
}

export const DossierFormWithErrorBoundary = withPageErrorBoundary(DossierForm);
