import type { ReactNode } from "react";
import { Typography } from "@mui/material";

interface DemandeurCiviliteProps {
  civilite?: string;
}

export function DemandeurCivilite({ civilite }: Readonly<DemandeurCiviliteProps>): ReactNode {
  switch (civilite?.toLocaleUpperCase()) {
    case "M":
      return "M.";
    case "MME":
      return (
        <>
          M
          <Typography
            component="span"
            sx={{ verticalAlign: "super", fontSize: "0.75rem" }}
            variant="inherit">
            me
          </Typography>
        </>
      );
    default:
      return civilite;
  }
}
