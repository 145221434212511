import type { ReactNode } from "react";
import { MenuItem, Grid2 as Grid, FormControlLabel, Radio } from "@mui/material";
import { Acceptation, DiametreBranchement, NatureConduite } from "models";
import type { DevisEauPatchDto } from "models";
import { FormSection } from "components/Layout";
import { useWatch } from "react-hook-form";
import {
  ControlledBooleanRadio,
  ControlledTextField,
  ControlledNumberField,
  ControlledRadioGroup,
} from "components/Inputs";
import Compteurs from "../DossierEau/Compteurs";
import AspectFinancierEauForm from "../DossierEau/AspectFinancierEauForm";
import {
  getAcceptationLabel,
  getDiametreBranchementLabel,
  getNatureConduiteLabel,
} from "utils/labelUtil";

export function DevisEauForm(): ReactNode {
  const ria = useWatch<DevisEauPatchDto, "ria">({ name: "ria" });

  return (
    <Grid container spacing={2} size={12}>
      <FormSection title="Devis accepté par le demandeur">
        <Grid size={{ xs: 12 }}>
          <ControlledRadioGroup name="acceptation">
            {Object.entries(Acceptation).map(([key, acceptation]) => {
              return (
                <FormControlLabel
                  key={key}
                  value={acceptation}
                  control={<Radio />}
                  label={getAcceptationLabel(acceptation)}
                  sx={{ pr: 2 }}
                />
              );
            })}
          </ControlledRadioGroup>
        </Grid>
      </FormSection>

      <FormSection title="Caractéristiques">
        <Grid size={{ xs: 12, lg: 3 }}>
          <ControlledBooleanRadio name="servitudeDePose" label="Servitude de pose" />
        </Grid>
        <Grid size={{ xs: 12, lg: 9 }}>
          <ControlledTextField
            name="descriptionTravaux"
            label="Description des travaux"
            multiline
          />
        </Grid>
      </FormSection>

      <FormSection title="Compteurs d'eau" size={{ xs: 12, lg: 8 }}>
        <Grid size={12}>
          <Compteurs name="compteurs" defaultValue={[]} />
        </Grid>
      </FormSection>

      <FormSection title="Robinet incendie armé *" size={{ xs: 12, lg: 4 }}>
        <Grid size={12}>
          <ControlledBooleanRadio
            name="ria"
            rules={{
              validate: (value) => typeof value === "boolean" || "Ce champ est obligatoire",
            }}
          />
        </Grid>
        {(ria ?? false) && (
          <Grid size={12}>
            <ControlledTextField name="calibreRia" label="Calibre RIA" />
          </Grid>
        )}
      </FormSection>

      <FormSection title="Branchement particulier">
        <Grid size={{ xs: 12, md: 6 }}>
          <ControlledBooleanRadio
            name="branchementParticulier.travauxDeTerrassement"
            label="Terrassement"
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <ControlledBooleanRadio
            name="branchementParticulier.percementDuMurCave"
            label="Percement du mur cave"
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <ControlledTextField
            name="branchementParticulier.natureConduite"
            select
            label="Nature de la conduite">
            {Object.entries(NatureConduite).map(([key, natureConduite]) => (
              <MenuItem key={key} value={natureConduite}>
                {getNatureConduiteLabel(natureConduite)}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <ControlledTextField name="branchementParticulier.diametre" select label="Diamètre en mm">
            {Object.entries(DiametreBranchement).map(([key, diametre]) => (
              <MenuItem key={key} value={diametre}>
                {getDiametreBranchementLabel(diametre)}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <ControlledNumberField
            label="Longueur en mètres"
            name="branchementParticulier.longueur"
            type="entier"
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3 }}>
          <ControlledNumberField
            label="Nombre de regards"
            name="branchementParticulier.nombreDeRegards"
            type="entier"
          />
        </Grid>

        <Grid size={12}>
          <ControlledTextField
            name="branchementParticulier.remarque"
            label="Remarques notées sur la demande de travaux"
            multiline
          />
        </Grid>
      </FormSection>
      <FormSection title="Aspect Financier" size={12}>
        <AspectFinancierEauForm />
      </FormSection>
    </Grid>
  );
}
