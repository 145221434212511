interface Option {
  label: string;
  value: number;
}

export const FRAIS_MO_OPTIONS: Array<Option> = [
  { label: "0%", value: 0 },
  { label: "6,92%", value: 0.0692 },
] as const;

export const FRAIS_DE_DOSSIER_OPTIONS: Array<Option> = [
  { label: "0%", value: 0 },
  { label: "3%", value: 0.03 },
] as const;

export const TVA_OPTIONS: Array<Option> = [
  { label: "0%", value: 0.0 },
  { label: "10%", value: 0.1 },
  { label: "20%", value: 0.2 },
] as const;

export const ACOMPTE_OPTIONS: Array<Option> = [
  { label: "0%", value: 0.0 },
  { label: "50%", value: 0.5 },
  { label: "80%", value: 0.8 },
  { label: "100%", value: 1.0 },
] as const;
