import { numericFormatter } from "react-number-format";

export function formatMontant(
  numberToFormat: string | number | undefined = 0,
  withEndEuro: boolean = false
): string {
  return `${numericFormatter(numberToFormat.toString(), {
    thousandSeparator: " ",
    decimalSeparator: ",",
    fixedDecimalScale: true,
    decimalScale: 2,
  })}${withEndEuro ? " €" : ""}`;
}
