import type { PropsWithChildren, ReactNode } from "react";
import type { Demandeur } from "models";
import { Chip, Link, Paper, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Grid from "@mui/material/Grid2";
import type { Grid2Props } from "@mui/material/Grid2";
import { grey } from "@mui/material/colors";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import type { SvgIconComponent } from "@mui/icons-material";
import { DemandeurCivilite } from ".";

interface DemandeurCardSectionProps extends PropsWithChildren {
  Icon: SvgIconComponent;
  title: string;
}

function DemandeurCardSection({
  Icon,
  title,
  children,
}: Readonly<DemandeurCardSectionProps>): ReactNode {
  return (
    <Grid container size={{ xs: 12, md: "grow" }} spacing={1}>
      <Grid container alignItems="center" spacing={1} size={12}>
        <Icon sx={{ color: grey[500] }} />
        <Typography variant="subtitle2">{title}</Typography>
      </Grid>
      <Grid size={12}>{children}</Grid>
    </Grid>
  );
}

interface ChantierDemandeurCardProps extends Grid2Props {
  demandeur?: Demandeur;
}

export function ChantierDemandeurCard({
  demandeur,
  ...gridProps
}: Readonly<ChantierDemandeurCardProps>): ReactNode {
  if (demandeur == null) {
    return null;
  }

  return (
    <Grid
      component={Paper}
      variant="outlined"
      container
      size={12}
      spacing={1}
      sx={{ p: 2 }}
      {...gridProps}>
      <Grid container size={12} alignItems="center" columnSpacing={3}>
        <PersonIcon />
        <Typography variant="subtitle2">
          <DemandeurCivilite civilite={demandeur.civilite} />{" "}
          <Typography color="primary" component="span" variant="subtitle2">
            {demandeur.nom?.toLocaleUpperCase()} {demandeur.prenom}
          </Typography>
        </Typography>
        <Chip
          variant="outlined"
          label={`Code SAP : ${demandeur.codeSap}`}
          size="small"
          sx={{ color: grey[600], borderColor: grey[300] }}
        />
      </Grid>
      <Grid container size={12} alignItems="flex-start" sx={{ mt: 1 }}>
        <DemandeurCardSection Icon={MailOutlineOutlinedIcon} title="Adresse de facturation">
          <Typography>
            {demandeur.adresseNumero} {demandeur.adresseRue}
            <br />
            {demandeur.adresseCodePostal} {demandeur.adresseCommune}
            <br />
            {demandeur.adressePays.toLocaleLowerCase() !== "france" && demandeur.adressePays}
          </Typography>
        </DemandeurCardSection>

        <DemandeurCardSection Icon={PhoneIcon} title="Téléphone">
          {demandeur.telephone != null && (
            <Typography>
              Personnel <Link href={`tel:${demandeur.telephone}`}>{demandeur.telephone}</Link>
            </Typography>
          )}
          {demandeur.portable != null && (
            <Typography>
              Portable <Link href={`tel:${demandeur.portable}`}>{demandeur.portable}</Link>
            </Typography>
          )}
        </DemandeurCardSection>

        <DemandeurCardSection Icon={AlternateEmailOutlinedIcon} title="E-Mail">
          <Typography sx={{ wordBreak: "keep-all" }}>
            <Link href={`mailto:${demandeur.email}`}>{demandeur.email}</Link>
          </Typography>
        </DemandeurCardSection>
      </Grid>
    </Grid>
  );
}
