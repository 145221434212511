import type { Dossier, DossierCreateDTO, PatchData } from "models";
import { Endpoints } from "enums";
import { opaleApiClient } from "./apiClients";

/**
 * Retourne les détails d'un dossier.
 * @param chantierId L'identifiant du chantier parent
 * @param dossierId L'identifiant du dossier
 */
async function getDossier(chantierId: string, dossierId: string): Promise<Dossier> {
  const url = `/${Endpoints.CHANTIERS}/${chantierId}/${Endpoints.DOSSIERS}/${dossierId}`;
  return opaleApiClient.get<Dossier, Dossier>(url);
}

/**
 * Retourne une liste de dossiers
 * @returns Tableau de dossiers
 */
async function getAllDossiers(): Promise<Array<Dossier>> {
  const url = `/${Endpoints.DOSSIERS}`;
  return opaleApiClient.get<Array<Dossier>, Array<Dossier>>(url);
}

/**
 * Modifie les données d'un dossier.
 * @param chantierId L'identifiant du chantier parent
 * @param dossierId L'identifiant du dossier
 * @param patchData la liste des propriétés à modifier
 */
async function patchDossier(
  chantierId: string,
  dossierId: string,
  patchData: Array<PatchData>
): Promise<Dossier | undefined> {
  const url = `/${Endpoints.CHANTIERS}/${chantierId}/${Endpoints.DOSSIERS}/${dossierId}`;
  return opaleApiClient.patch<Dossier, Dossier, Array<PatchData>>(url, patchData);
}

/**
 * Crée un dossier dans un chantier existant.
 * @param chantierId L'identifiant du chantier parent
 * @returns l'id du dossier créé
 */
async function createDossier(chantierId: string, payload: DossierCreateDTO): Promise<string> {
  const url = `/${Endpoints.CHANTIERS}/${chantierId}/${Endpoints.DOSSIERS}`;
  return opaleApiClient.post<string, string, DossierCreateDTO>(url, payload);
}

export const dossierService = {
  getDossier,
  getAllDossiers,
  patchDossier,
  createDossier,
};
