import type { ReactNode } from "react";
import { Typography } from "@mui/material";
import type { File } from "models";
import FileIcon from "./FileIcon";

interface FileItemProps {
  file: File;
}

function FileItem({ file }: Readonly<FileItemProps>): ReactNode {
  return (
    <>
      <FileIcon file={file} fontSize="small" />
      <Typography sx={{ pl: 1, wordBreak: "break-word" }}>{file.name}</Typography>
    </>
  );
}

export default FileItem;
