import { useEffect } from "react";
import type { ReactNode } from "react";
import { Grid2 as Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useDevis } from "pages/Chantier/providers/DevisProvider";
import { getAcceptationLabel } from "utils/labelUtil";
import financeUtils from "utils/financeUtils";
import { Acceptation } from "models";

interface DevisSelectProps {
  label: string;
  fullWidth?: boolean;
  value?: string;
  onChange: (newValue: string) => void;
}

export function DevisSelect({
  label,
  fullWidth = true,
  value,
  onChange,
}: Readonly<DevisSelectProps>): ReactNode {
  const { dossierDevis } = useDevis();

  useEffect(() => {
    if (dossierDevis.length > 0) {
      let preSelectedDevisId = "";
      if (dossierDevis.length === 1) {
        preSelectedDevisId = dossierDevis[0].id;
      } else {
        const devisAccepte = dossierDevis.find((devis) => devis.acceptation === Acceptation.OUI);
        if (devisAccepte != null) {
          preSelectedDevisId = devisAccepte.id;
        }
      }

      if (preSelectedDevisId != null) {
        onChange(preSelectedDevisId);
      }
    }
  }, [dossierDevis, onChange]);

  return (
    <TextField
      label={label}
      fullWidth={fullWidth}
      select
      value={value ?? ""}
      onChange={(e) => {
        onChange(e.target.value);
      }}>
      <MenuItem value="" disabled>
        <em>Sélectionnez un Devis à utiliser dans les courriers</em>
      </MenuItem>
      {dossierDevis?.map((devis) => (
        <MenuItem key={devis.id} value={devis.id}>
          <Grid container sx={{ width: "100%" }} spacing={1}>
            <Grid size="grow">
              Montant Total:{" "}
              <Typography component="span" sx={{ fontWeight: 500, color: "primary.main" }}>
                {financeUtils
                  .toRoundedEuroString(devis.aspectFinancier?.montantTotalTtc)
                  .replace(",00", "")}
              </Typography>
            </Grid>
            <Grid size="grow">
              Accepté:{" "}
              <Typography component="span" sx={{ fontWeight: 500, color: "primary.main" }}>
                {getAcceptationLabel(devis.acceptation)}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
      ))}
    </TextField>
  );
}
