import type { ReactNode } from "react";
import { routesConfig } from "config/app-config";
import type { RouteDefinition } from "config/app-config";
import {
  DossierAssFormAdministratifWithErrorBoundary,
  DossierAssFormCourriersWithErrorBoundary,
  DossierAssFormTechniqueWithErrorBoundary,
} from "../components/DossierAss";
import {
  DossierCipaFormAdministratifWithErrorBoundary,
  DossierCipaFormCourriersWithErrorBoundary,
} from "../components/DossierCipa";
import {
  DossierEauFormAdministratifWithErrorBoundary,
  DossierEauFormCourriersWithErrorBoundary,
} from "../components/DossierEau";
import { DossierPacFormAdministratifWithErrorBoundary } from "../components/DossierPac";
import { DossierCipaFormControleWithErrorBoundary } from "../components/DossierCipa/DossierCipaFormControleWithErrorBoundary";
import { DossierEauFormTechniqueWithErrorBoundary } from "../components/DossierEau/DossierEauFormTechniqueWithErrorBoundary";

export type DossierSectionDefinition = RouteDefinition & {
  component: (props: Readonly<unknown>) => ReactNode;
};

export const dossierEauSections: Array<DossierSectionDefinition> = [
  {
    ...routesConfig.chantierDossierAdministratif,
    title: "Administratif",
    component: DossierEauFormAdministratifWithErrorBoundary,
  },
  {
    ...routesConfig.chantierDossierTechnique,
    title: "Technique & devis",
    component: DossierEauFormTechniqueWithErrorBoundary,
  },
  {
    ...routesConfig.chantierDossierCourriers,
    title: "Courriers",
    component: DossierEauFormCourriersWithErrorBoundary,
  },
] as const;

export const dossierAssSections: Array<DossierSectionDefinition> = [
  {
    ...routesConfig.chantierDossierAdministratif,
    title: "Administratif",
    component: DossierAssFormAdministratifWithErrorBoundary,
  },
  {
    ...routesConfig.chantierDossierTechnique,
    title: "Technique & devis",
    component: DossierAssFormTechniqueWithErrorBoundary,
  },
  {
    ...routesConfig.chantierDossierCourriers,
    title: "Courriers",
    component: DossierAssFormCourriersWithErrorBoundary,
  },
] as const;

export const dossierCipaSections: Array<DossierSectionDefinition> = [
  {
    ...routesConfig.chantierDossierAdministratif,
    title: "Administratif",
    component: DossierCipaFormAdministratifWithErrorBoundary,
  },
  {
    ...routesConfig.chantierDossierControles,
    title: "Contrôles",
    component: DossierCipaFormControleWithErrorBoundary,
  },
  {
    ...routesConfig.chantierDossierCourriers,
    title: "Courriers",
    component: DossierCipaFormCourriersWithErrorBoundary,
  },
] as const;

export const dossierPacSections: Array<DossierSectionDefinition> = [
  {
    ...routesConfig.chantierDossierAdministratif,
    title: "Administratif",
    component: DossierPacFormAdministratifWithErrorBoundary,
  },
] as const;
