import { useCallback, useEffect, useState, type ReactNode } from "react";
import Grid from "@mui/material/Grid2";
import type {
  Chantier,
  CourrierMetadata,
  DocumentDataConditionsDto,
  DocumentGenerationAuditForTimeline,
  DossierAss,
  DossierCipa,
  DossierEau,
} from "models";
import { FormSection } from "components/Layout";
import { LoadingScreen } from "components/Loading";
import { grey } from "@mui/material/colors";
import { Link, TextField } from "@mui/material";
import { useDestinataireCopie } from "pages/Chantier/hooks/useDestinataireCopie";
import { courriersService, filesService } from "services";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { DocumentGenerationButton } from "./DocumentGenerationButton";
import { useSnackbarErrorHandler } from "utils/errorHandling";
import { sharepointService } from "services/sharepointService";
import { enqueueSnackbar } from "notistack";
import { ToastMessages } from "enums";
import { DocumentCourriersConditions } from "./DocumentCourriersConditions";
import { DocumentGenerationTimeline } from "./DocumentGenerationTimeline";
import { DocumentCourriersConditionsSkeleton } from "./DocumentCourriersConditionsSkeleton";
import { DocumentCadreButton } from "./DocumentCadreButton";
import { DevisSelect } from "./DevisSelect";
import { DevisProvider } from "pages/Chantier/providers/DevisProvider";

interface DossierCourriersFormProps {
  chantier?: Chantier;
  dossier?: DossierAss | DossierEau | DossierCipa;
  courriersMetadata?: Array<CourrierMetadata>;
  isLoading: boolean;
}

export function DossierCourriersForm({
  chantier,
  dossier,
  courriersMetadata,
  isLoading,
}: Readonly<DossierCourriersFormProps>): ReactNode {
  const { destinataireCopie, patchDestinataireCopie, onDestinataireCopieChange } =
    useDestinataireCopie(chantier);
  const [devisEauId, setDevisEauId] = useState<string>();
  const [devisAssId, setDevisAssId] = useState<string>();

  const [documentAudits, setDocumentAudits] = useState<Array<DocumentGenerationAuditForTimeline>>(
    []
  );
  const { catchErrors, isLoading: isLoadingDocumentAudits } = useSnackbarErrorHandler({
    default: () => {
      enqueueSnackbar({
        variant: "error",
        message: ToastMessages.ERROR_LOADING_GENERATED_DOCUMENTS_AUDIT,
      });
    },
  });
  const loadAudits = useCallback(() => {
    async function loadAuditsAsync(): Promise<void> {
      if (chantier != null && dossier != null) {
        const results = await courriersService.getDossierDocumentGeneration(
          chantier.id,
          dossier.id
        );
        const audits = results.map<DocumentGenerationAuditForTimeline>((audit) => ({
          ...audit,
          cheminRelatifDocument: sharepointService.getOpenSharepointFileInDesktopAppLink(
            filesService.getCourrierUrl(dossier, chantier, audit.nomDocument)
          ),
          typeDossier: dossier.type,
        }));
        setDocumentAudits(audits);
      }
    }
    void catchErrors(loadAuditsAsync);
  }, [catchErrors, chantier, dossier]);
  useEffect(loadAudits, [loadAudits]);

  const { catchErrors: catchErrorsConditions, isLoading: isConditionsLoading } =
    useSnackbarErrorHandler();
  const [conditions, setConditions] = useState<DocumentDataConditionsDto>();
  useEffect(() => {
    async function loadConditions(): Promise<void> {
      if (chantier != null) {
        const conditionsChantier = await courriersService.getDocumentConditions(
          chantier.id,
          devisEauId
        );
        setConditions(conditionsChantier);
      }
    }
    void catchErrorsConditions(loadConditions);
  }, [catchErrorsConditions, chantier, devisEauId]);

  if (dossier == null || chantier == null || isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Grid container spacing={2} sx={{ p: 2, background: grey[200] }} size={12}>
      <Grid container spacing={2} size={{ xs: 12, lg: 7 }} sx={{ alignContent: "start" }}>
        <FormSection title="Générer un courrier">
          <Grid size={12} container spacing={3}>
            <Grid container size={12}>
              <TextField
                label="Copie à"
                value={destinataireCopie ?? ""}
                onChange={onDestinataireCopieChange}
                fullWidth
              />
            </Grid>
            {chantier.dossierEau != null && (
              <DevisProvider idChantier={chantier.id} idDossier={chantier.dossierEau.id}>
                <DevisSelect
                  label="Devis EAU *"
                  fullWidth
                  value={devisEauId}
                  onChange={setDevisEauId}
                />
              </DevisProvider>
            )}
            {chantier.dossierAss != null && (
              <DevisProvider idChantier={chantier.id} idDossier={chantier.dossierAss.id}>
                <DevisSelect
                  label="Devis ASS *"
                  fullWidth
                  value={devisAssId}
                  onChange={setDevisAssId}
                />
              </DevisProvider>
            )}
            <Grid container spacing={1} size={12}>
              {courriersMetadata?.map((courrierMetaData) => (
                <Grid
                  key={courrierMetaData.type}
                  size={12}
                  mb={courrierMetaData.avecMarge === true ? 2 : 0}>
                  {courrierMetaData.type !== "Cadre" ? (
                    <DocumentGenerationButton
                      courrierMetaData={courrierMetaData}
                      chantier={chantier}
                      dossier={dossier}
                      devisEauId={devisEauId}
                      devisAssId={devisAssId}
                      beforeGeneration={patchDestinataireCopie}
                      afterGeneration={loadAudits}
                    />
                  ) : (
                    <DocumentCadreButton chantier={chantier} dossier={dossier} />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </FormSection>

        <FormSection title="Conditions utilisées">
          {isConditionsLoading ? (
            <DocumentCourriersConditionsSkeleton />
          ) : (
            <DocumentCourriersConditions conditions={conditions} />
          )}
        </FormSection>
      </Grid>

      <FormSection title="Historique des courriers" size={{ xs: 12, lg: 5 }} container>
        <Grid size={12}>
          <DocumentGenerationTimeline
            isLoading={isLoadingDocumentAudits}
            documentAudits={documentAudits}
          />
        </Grid>
        <Grid size={12}>
          <Link href={filesService.getChantierFolderUrl(chantier)} target="_blank">
            Ouvrir le répertoire <b>CHANTIER-{chantier.reference}</b> dans Sharepoint
            <OpenInNewIcon sx={{ fontSize: 10, ml: 0.5, verticalAlign: "super" }} />
          </Link>
        </Grid>
        <Grid size={12}>
          <Link href={filesService.getDossierFolderUrl(dossier, chantier)} target="_blank">
            Ouvrir le répertoire <b>{dossier.folderName}</b> dans Sharepoint
            <OpenInNewIcon sx={{ fontSize: 10, ml: 0.5, verticalAlign: "super" }} />
          </Link>
        </Grid>
      </FormSection>
    </Grid>
  );
}
