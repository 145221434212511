import { type ReactNode } from "react";
import Grid from "@mui/material/Grid2";
import type { DossierCipa } from "models";
import { CourriersDossierCipaDefinitions } from "models";
import { withPageErrorBoundary } from "utils/errorHandling";
import { useDossier, useChantier } from "providers";
import DossierSections from "../DossierSections";
import { dossierCipaSections } from "../../routes/dossiersSectionsDefinitions";
import { DossierCourriersForm } from "../Courriers";

function DossierCipaFormCourriers(): ReactNode {
  const { chantier, isLoading: isChantierLoading } = useChantier();
  const { dossier, isLoading: isDossierLoading = false } = useDossier<DossierCipa>();

  return (
    <Grid container>
      <DossierSections isLoading={false} sections={dossierCipaSections} noSaveButton />
      <DossierCourriersForm
        chantier={chantier}
        dossier={dossier}
        isLoading={isChantierLoading || isDossierLoading}
        courriersMetadata={CourriersDossierCipaDefinitions}
      />
    </Grid>
  );
}

export const DossierCipaFormCourriersWithErrorBoundary =
  withPageErrorBoundary(DossierCipaFormCourriers);
