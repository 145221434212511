import { Endpoints } from "enums";
import type { Controle, ControleCreateDto } from "models";
import { opaleApiClient } from "./apiClients";

/**
 * Créé un contrôle en base de donnée
 * @param data Les données du contrôle.
 * @param chantierId L'identifiant du chantier parent.
 * @param dossierId L'identifiant du dossier.
 */
async function postControle(
  data: ControleCreateDto,
  chantierId?: string,
  dossierId?: string
): Promise<void> {
  const url = `/${Endpoints.CHANTIERS}/${chantierId}/${Endpoints.DOSSIERS}/${dossierId}/${Endpoints.CONTROLES}`;
  await opaleApiClient.post<ControleCreateDto>(url, data);
}

/**
 * Supprime un contrôle d'un dossier Cipa.
 * @param controleId L'identifiant du contrôle.
 * @param chantierId L'identifiant du chantier parent.
 * @param dossierId L'identifiant du dossier.
 */
async function deleteControle(
  controleId: string,
  chantierId?: string,
  dossierId?: string
): Promise<void> {
  const url = `/${Endpoints.CHANTIERS}/${chantierId}/${Endpoints.DOSSIERS}/${dossierId}/${Endpoints.CONTROLES}/${controleId}`;
  await opaleApiClient.delete<Controle>(url);
}

export const controlesService = {
  postControle,
  deleteControle,
};
