import { Fragment } from "react";
import type { ReactNode } from "react";
import { SdeappsError, useSnackbarErrorHandler, withPageErrorBoundary } from "utils/errorHandling";
import { FormSection } from "components/Layout";
import Grid from "@mui/material/Grid2";
import { grey } from "@mui/material/colors";
import DossierSections from "../DossierSections";
import { dossierCipaSections } from "../../routes/dossiersSectionsDefinitions";
import { useDossier } from "providers";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import type { Controle, ControleCreateDto, DossierCipa } from "models";
import { enqueueSnackbar } from "notistack";
import { dateUtil } from "@sdeapps/react-core";
import { controlesService } from "services/controleService";
import { Button, Divider, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastMessages } from "enums";
import { ControlledBooleanRadio, ControlledDateTime, ControlledTextField } from "components/Inputs";

const validDateRule = {
  validate: (value: Date | string | undefined) =>
    dateUtil.isValid(value) || "Veuillez saisir une date valide",
};

function DossierCipaFormControle(): ReactNode {
  const { dossier, isLoading: isDossierLoading = false, updateDossier } = useDossier<DossierCipa>();

  const formMethods = useForm<ControleCreateDto>({
    shouldFocusError: false,
    defaultValues: {
      dateControle: null,
      accord: undefined,
      observations: undefined,
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = formMethods;

  const { catchErrors, isLoading } = useSnackbarErrorHandler({
    defaultIsLoading: false,
    SdeappsErrorBadRequest: (error) => {
      enqueueSnackbar({
        variant: "error",
        message: error.message,
      });
    },
  });

  const sendData: SubmitHandler<ControleCreateDto> = async function (
    controle: ControleCreateDto
  ): Promise<void> {
    if (controle == null) {
      throw new SdeappsError("CONTROLE NE PEUT PAS ETRE NUL !!!!");
    }
    if (!isValid) {
      console.warn("La méthode ne doit pas être appelée si le formulaire contient des erreurs.");
      return;
    }

    await catchErrors(async () => {
      const sendControle: ControleCreateDto = {
        ...controle,
        dateControle: dateUtil.format(controle.dateControle, "yyyy-MM-dd"),
      };
      await controlesService.postControle(sendControle, dossier?.idChantier, dossier?.id);
      reset({
        dateControle: null,
        accord: null,
        observations: undefined,
      });
      updateDossier();
    });
  };

  async function deleteControle(controleId: string | undefined): Promise<void> {
    if (controleId != null) {
      await controlesService.deleteControle(controleId, dossier?.idChantier, dossier?.id);
      updateDossier();
    }
  }

  return (
    <Grid container>
      <DossierSections isLoading={false} sections={dossierCipaSections} noSaveButton />
      <Grid container spacing={2} sx={{ p: 2, background: grey[200] }} size={12}>
        <FormSection title="Contrôles" size={{ xs: 12 }}>
          <Grid size={12}>
            <Grid container spacing={1} size={12}>
              <Grid container size={12}>
                <Grid container size={3} sx={{ paddingX: 2 }}>
                  <Typography variant="subtitle2">Date du contrôle</Typography>
                </Grid>
                <Grid container size={2} sx={{ paddingX: 2 }}>
                  <Typography variant="subtitle2">Accord</Typography>
                </Grid>
                <Grid container size={7} sx={{ paddingX: 2 }}>
                  <Typography variant="subtitle2">Observations</Typography>
                </Grid>
              </Grid>
              {dossier?.controles
                ?.sort((a, b) => dateUtil.compareAsc(a.dateControle, b.dateControle))
                .map((controle: Controle) => (
                  <Fragment key={controle.id}>
                    <Grid size={12}>
                      <Divider />
                    </Grid>
                    <Grid container alignItems="center" size={12}>
                      <Grid size={3} sx={{ paddingX: 2 }}>
                        <Typography>
                          {dateUtil.format(controle.dateControle, "dd/MM/yyyy")}
                        </Typography>
                      </Grid>
                      <Grid size={2} sx={{ paddingX: 2 }}>
                        <Typography>{controle.accord ? "Oui" : "Non"}</Typography>
                      </Grid>
                      <Grid size="grow" sx={{ paddingX: 2 }}>
                        <Typography>{controle.observations}</Typography>
                      </Grid>
                      <Grid size="auto">
                        <IconButton
                          onClick={() => {
                            void deleteControle(controle.id);
                          }}
                          size="small">
                          <Tooltip
                            title="Supprimer ce contrôle"
                            placement="bottom"
                            disableInteractive>
                            <DeleteIcon color="error" />
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Fragment>
                ))}
              {(isDossierLoading || isLoading) && (
                <Grid container alignItems="center" size={12}>
                  <Grid size={3}>
                    <Skeleton />
                  </Grid>
                  <Grid size={2}>
                    <Skeleton />
                  </Grid>
                  <Grid size="grow">
                    <Skeleton />
                  </Grid>
                </Grid>
              )}
              <FormProvider {...formMethods}>
                <Grid
                  container
                  component="form"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onSubmit={handleSubmit(sendData, () => {
                    enqueueSnackbar({
                      variant: "error",
                      message: ToastMessages.ERROR_FORM_VALIDATION,
                    });
                  })}
                  spacing={1}
                  size={12}
                  alignItems="center">
                  <Grid container size={3}>
                    <ControlledDateTime
                      label="Date du contrôle *"
                      name="dateControle"
                      rules={{ ...validDateRule, required: "Ce champ est obligatoire." }}
                    />
                  </Grid>
                  <Grid container size={2} justifyContent="center">
                    <ControlledBooleanRadio
                      name="accord"
                      rules={{ validate: (value) => value != null || "Ce champ est obligatoire." }}
                      defaultValue={null}
                    />
                  </Grid>
                  <Grid container size={5}>
                    <ControlledTextField
                      name="observations"
                      label="Observations *"
                      multiline
                      rules={{ required: "Ce champ est obligatoire." }}
                    />
                  </Grid>
                  <Grid container size={2}>
                    <Button startIcon={<AddIcon />} type="submit" variant="contained" fullWidth>
                      Ajouter
                    </Button>
                  </Grid>
                </Grid>
              </FormProvider>
            </Grid>
          </Grid>
        </FormSection>
      </Grid>
    </Grid>
  );
}

export const DossierCipaFormControleWithErrorBoundary =
  withPageErrorBoundary(DossierCipaFormControle);
