import type {
  DossierAss,
  DossierCipa,
  DossierEau,
  DossierPac,
  StatutDossierAss,
  StatutDossierCipa,
  StatutDossierEau,
  StatutDossierPac,
} from ".";
import type { Perimetre } from "models";

export enum TypeDossier {
  EAU = "EAU",
  ASS = "ASS",
  CIPA = "CIPA",
  PAC = "PAC",
}

export enum NatureConduite {
  PVC = "PVC",
  GRES = "GRES",
  FONTE = "FONTE",
  BETON = "BETON",
  PVC_PRESSION = "PVC_PRESSION",
  PEHD = "PEHD",
  AUTRE = "AUTRE",
}

export enum DiametreBranchement {
  d0 = "_0",
  d32 = "_32",
  d40 = "_40",
  d50 = "_50",
  d63 = "_63",
  d90 = "_90",
  d100 = "_100",
  d125 = "_125",
  d150 = "_150",
  d160 = "_160",
  d200 = "_200",
  d250 = "_250",
  d300 = "_300",
}

export interface DossierCreateDTO {
  idAvis?: string;
  type: TypeDossier;
  perimetre?: Perimetre;
}

export interface DossierBase extends DossierCreateDTO {
  id: string;
  idChantier: string;
  technicien: { id: string; displayName?: string };
  reference?: string;
  /**
   * chemin dans Sharepoint, depuis l'intérieur du répertoire du Chantier vers le répertoire du Dossier
   * ex: "EAU 123 CUL"
   */
  folderName: string;
}

export type Dossier = DossierEau | DossierAss | DossierCipa | DossierPac;

export type StatutDossier =
  | StatutDossierEau
  | StatutDossierAss
  | StatutDossierCipa
  | StatutDossierPac;

export enum AllStatutDossier {
  EN_COURS_TRAITEMENT = "En cours de traitement",
  ATTENTE_REALISATION_TRAVAUX = "En attente de réalisation des travaux",
  ATTENTE_DOCUMENTS = "En attente de documents",
  TRAVAUX_REALISES_ATTENTE_FACTURE_FOURNISSEUR = "Travaux réalisés attente facture fournisseur",
  INCOMPLET = "Incomplet",
  ERREUR_DEMANDE = "Erreur demande",
  PROBLEME_TECHNIQUE = "Problème technique",
  ANNULE = "Annulé",
  AUTRE = "Autres (voir observations)",
  COMPLET = "Complet",
  RELANCE_CLOTURE = "Relancé clôturé",
  CLOS = "Clos",
  CLOS_EXECUTES = "Clos (travaux exécutés)",
}
