import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { grey } from "@mui/material/colors";
import type { Devis, DevisAss, DevisAssPatchDto, DevisEau, DevisEauPatchDto } from "models";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDevis } from "../../providers/DevisProvider";
import { ToastMessages } from "enums";
import { enqueueSnackbar } from "notistack";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { useSnackbarErrorHandler, withComponentErrorBoundary } from "utils/errorHandling";
import { devisPatchUtils, isDevisEau } from "utils/devisPatchUtil";
import { compare } from "fast-json-patch";
import type { Operation } from "fast-json-patch";
import { DevisAssForm, DevisEauForm } from ".";
import financeUtils from "utils/financeUtils";
import { getAcceptationLabel } from "utils/labelUtil";

function getModifyDTO(devis: Devis): DevisEauPatchDto | DevisAssPatchDto {
  return isDevisEau(devis)
    ? devisPatchUtils.fromDevisEauToDevisEauPatchDto(devis)
    : devisPatchUtils.fromDevisAssToDevisAssPatchDto(devis);
}

interface DevisAcccordionProps<T extends DevisEau | DevisAss> {
  defaultExpanded?: boolean;
  devis: T;
}

function DevisAcccordion<T extends DevisEau | DevisAss>({
  defaultExpanded = false,
  devis: distantDevis,
}: Readonly<DevisAcccordionProps<T>>): ReactNode {
  const [isOpen, setIsOpen] = useState<boolean>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [patchData, setPatchData] = useState<Array<Operation>>([]);
  const { deleteDevis, isDeleting, patchDevis } = useDevis();
  const { catchErrors, isLoading } = useSnackbarErrorHandler();

  const formMethods = useForm<DevisEauPatchDto | DevisAssPatchDto>({
    defaultValues: isDevisEau(distantDevis)
      ? { compteurs: [], branchementParticulier: {} }
      : { branchementParticulier: {} },
  });
  const { control, handleSubmit, reset } = formMethods;
  const devis = useWatch({ control });

  useEffect(() => {
    const _patchData: Array<Operation> = compare(getModifyDTO(distantDevis), devis);
    setPatchData(_patchData);
  }, [devis, distantDevis]);

  useEffect(() => {
    // On met les données du formulaire à jour à chaque update du devis distant
    reset(getModifyDTO(distantDevis));
  }, [distantDevis, reset]);

  useEffect(() => {
    if (isOpen == null) {
      setIsOpen(defaultExpanded);
    }
  }, [defaultExpanded, isOpen]);

  function sendData(): void {
    void catchErrors(async (): Promise<void> => {
      await patchDevis(distantDevis.id, patchData);
    });
  }

  function toggleIsOpen(): void {
    setIsOpen(!(isOpen === true));
  }

  function openDeleteDialog(e: React.MouseEvent): void {
    setIsDeleteDialogOpen(true);
    stop(e);
  }

  function closeDeleteDialog(): void {
    setIsDeleteDialogOpen(false);
  }

  function _deleteDevis(): void {
    void deleteDevis(distantDevis.id);
  }

  function stop(e: React.MouseEvent): void {
    e.stopPropagation();
  }

  return (
    <FormProvider {...formMethods}>
      <Grid
        size={12}
        container
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(sendData, () => {
          enqueueSnackbar({
            variant: "error",
            message: ToastMessages.ERROR_FORM_VALIDATION,
          });
        })}>
        <Accordion
          variant="outlined"
          expanded={isOpen ?? false}
          onChange={toggleIsOpen}
          sx={(theme) => {
            return {
              position: "relative",
              borderColor: `${distantDevis.type.toLowerCase()}.main`,
              borderWidth: "2px",
              transition: "box-shadow 150ms ease",
              "&:hover": { boxShadow: isOpen === true ? 0 : theme.shadows[2] },
            };
          }}>
          <AccordionSummary
            sx={{
              position: "sticky",
              top: "82px",
              background: "white",
              zIndex: 2,
              borderBottom: "transparent 1px solid",
              borderColor: isOpen === true ? `${distantDevis.type.toLowerCase()}.main` : undefined,
              borderRadius: isOpen === true ? "4px 4px 0 0" : "4px",
            }}>
            <Grid container size={12} alignItems="center" spacing={2}>
              <Grid size="auto">
                <IconButton size="small">
                  {isOpen === true ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </IconButton>
              </Grid>

              <Grid container size="grow" alignItems="center">
                <Grid size="grow">
                  <Typography>Acceptation par le demandeur</Typography>
                  <Typography fontWeight={500} color="primary">
                    {getAcceptationLabel(devis.acceptation)}
                  </Typography>
                </Grid>
                <Grid size="grow">
                  <Typography>
                    Montant Total <Typography variant="caption">TTC</Typography>
                  </Typography>
                  <Typography fontWeight={500} color="primary">
                    {financeUtils
                      .toRoundedEuroString(devis.aspectFinancier?.montantTotalTtc)
                      .replace(",00", "")}
                  </Typography>
                </Grid>

                <Grid
                  container
                  size={{ xs: 12, md: "auto" }}
                  alignItems="center"
                  justifyContent="flex-end">
                  <Grid size="auto">
                    <IconButton onClick={openDeleteDialog} size="small" loading={isDeleting}>
                      <Tooltip title="Supprimer ce devis" placement="bottom">
                        <DeleteIcon color="error" />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                  <Grid size="auto">
                    <Button
                      type="submit"
                      loading={isLoading}
                      color="info"
                      variant="contained"
                      startIcon={<SaveIcon />}
                      onClick={stop}
                      loadingPosition="start"
                      disabled={patchData.length === 0}>
                      Enregistrer
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: grey[200], p: 2, borderRadius: "0 0 4px 4px" }}>
            {isDevisEau(distantDevis) ? <DevisEauForm /> : <DevisAssForm />}
          </AccordionDetails>
        </Accordion>
        <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
          <DialogTitle>Confirmation requise</DialogTitle>
          <DialogContent>
            <DialogContentText>Êtes-vous sûr de vouloir supprimer ce devis ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDeleteDialog} color="error">
              Non
            </Button>
            <Button
              onClick={_deleteDevis}
              variant="contained"
              autoFocus
              loading={isDeleting}
              startIcon={<DeleteIcon />}>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </FormProvider>
  );
}

export const DevisAcccordionWithErrorBoundary = withComponentErrorBoundary(DevisAcccordion);
