import type { DiametreBranchement, DossierBase, NatureConduite, TypeDossier } from "models";

export enum StatutDossierAss {
  EN_COURS_TRAITEMENT = "En cours de traitement",
  ATTENTE_REALISATION_TRAVAUX = "En attente de réalisation des travaux",
  ATTENTE_DOCUMENTS = "En attente de documents",
  TRAVAUX_REALISES_ATTENTE_FACTURE_FOURNISSEUR = "Travaux réalisés attente facture fournisseur",
  CLOS = "Clos (travaux exécutés)",
  ANNULE = "Annulé",
  ERREUR_DEMANDE = "Erreur demande",
  AUTRE = "Autres (voir observations)",
}

export interface BranchementParticulierAss {
  natureConduite?: NatureConduite;
  longueur?: number;
  diametre?: DiametreBranchement;
  nombreDeRegards?: number;
}

export enum TypeInterventionAss {
  RESEAU_UNITAIRE = "Branchement sur réseau unitaire",
  RESEAU_SEPARATIF = "Branchement sur réseau séparatif",
  AUTRE = "Autre (voir observations)",
}

export interface DossierAss extends DossierBase {
  statutDemande: StatutDossierAss;
  type: TypeDossier.ASS;
  dateDemande: string;

  // #region Données administratives
  dateDossierComplet?: string;
  dateEnvoiDevisClient?: string;
  dateAcceptationTravaux?: string;
  dateDemandePiecesDossier?: string;
  dateReceptionAcompte?: string;
  dateDebutTravaux?: string;
  dateRealisationTravaux?: string;

  typeIntervention?: TypeInterventionAss;
  observations?: string;

  permissionVoirie?: boolean | null;
  dateDemandeVoirie?: string;
  dateAutorisationVoirie?: string;

  travauxRegie?: boolean | null;
  idFournisseur?: string;
  libelleFournisseur?: string;
  dateDemandeDevis?: string;
  dateRetourDevis?: string;
  dateBonCommande?: string;
  // #endregion

  destinataireCopie?: string;
}

export interface DossierAssAdministratifModifyDTO {
  idTechnicien?: string;
  nameTechnicien?: string;
  mailTechnicien?: string;
  centreTechnicien?: string;
  telephoneTechnicien?: string;

  statutDemande: StatutDossierAss;
  dateDemande: Date;

  dateDossierComplet?: Date;
  dateEnvoiDevisClient?: Date;
  dateAcceptationTravaux?: Date;
  dateDemandePiecesDossier?: Date;
  dateReceptionAcompte?: Date;
  dateDebutTravaux?: Date;
  dateRealisationTravaux?: Date;

  typeIntervention?: TypeInterventionAss;
  observations?: string;

  permissionVoirie?: boolean | null;
  dateDemandeVoirie?: Date;
  dateAutorisationVoirie?: Date;

  travauxRegie?: boolean | null;
  idFournisseur?: string;
  libelleFournisseur?: string;
  dateDemandeDevis?: Date;
  dateRetourDevis?: Date;
  dateBonCommande?: Date;
}
